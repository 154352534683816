import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';
import menus from '../mocks/footer-menus';

const useMenuFooterSecondaryItemsStaticQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMenuLinkContentFooterSecondary(
        sort: { order: ASC, fields: [weight] }
        filter: { enabled: { eq: true } }
      ) {
        edges {
          node {
            drupal_id
            title
            weight
            link {
              uri
            }
          }
        }
      }
    }
  `);

  const saveNode = () => {
    return data.allMenuLinkContentFooterSecondary.edges || menus.second;
  };

  useMemo(() => saveNode(), data.allMenuLinkContentFooterSecondary);

  return saveNode();
};

export default useMenuFooterSecondaryItemsStaticQuery;
