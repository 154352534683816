import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { digestClassNodes } from '../libs/class/class-digest';

/**
 *
 * @param id
 * @param idKey
 * @returns {[*, *, *]}
 */
export const useClassesStaticQuery = (id = 0, idKey = 'nid') => {
  let [classes, classFilters] = useLoadClassNodes();

  // Filter by ID.
  if (id) {
    // console.log('prefilter classes', id, results);
    classes = classes.all.find((node) => node[idKey] === parseInt(id));
    // console.log('filtered classes', id, results);
    classes = classes || {};
  }

  return [classes, classFilters];
};

/**
 *
 * @returns {*[]}
 */
const useLoadClassNodes = () => {
  const { allNodeClassSchedule } = useStaticQuery(graphql`
    query loadClassScheduleListRef {
      allNodeClassSchedule {
        edges {
          node {
            id
            drupal_internal__nid
            title
            field_available_seats
            field_class_format
            field_end_time
            field_class_schedule_loft_id
            field_price
            field_start_date
            field_start_time
            field_wine_cost
            field_with_wine
            path {
              langcode
            }
            relationships {
              field_class {
                id
                drupal_internal__nid
                title
                field_price
                field_class_loft_id
                field_for_adults
                field_duration
                field_description
                relationships {
                  field_sub_category {
                    id
                    name
                    weight
                    drupal_internal__tid
                  }
                  field_featured_image {
                    uri {
                      url
                    }
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxWidth: 945) {
                          ...GatsbyImageSharpFluid
                        }
                        featured: fixed(width: 602, height: 602, cropFocus: CENTER) {
                          ...GatsbyImageSharpFixed
                        }
                        teaser: fluid(maxHeight: 203) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              field_store {
                id
                drupal_internal__nid
                field_store_loft_id
                field_store_hidden_from_website
                title
              }
              field_instructor {
                id
                drupal_internal__tid
                name
                field_bio {
                  format
                  processed
                  summary
                  value
                }
                relationships {
                  field_signature {
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxWidth: 160) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  field_image {
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxWidth: 160) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  // const allNodeClassSchedule = {};
  return useMemo(() => digestClassNodes(allNodeClassSchedule), [allNodeClassSchedule]);
};
