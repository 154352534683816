const moment = require('moment');

/**
 * @private
 * @param val
 * @returns {string|*}
 */
const addZeroPadding = (val) => {
  if (parseInt(val) < 10) {
    return `0${parseInt(val)}`;
  } else {
    return val;
  }
};

/**
 * Generates a date friendly for CRM calls.
 * @param date
 * @returns {string}
 */
const crmFriendlyDate = (date) => {
  return `${addZeroPadding(
    date.value.getMonth() + 1,
  )}/${date.value.getDate()}/${date.value.getFullYear()}`;
};

/**
 * To ensure date and time formats can be consistently leveraged, with the timezone corrections.
 * @param date
 * @param time
 * @param format
 * @returns {moment.Moment}
 */
const dateAndTimeWithTimezoneCorrection = (date, time, format = 'YYYY-MM-DD h:mma') => {
  return moment(`${date} ${time}`, format, true).utcOffset('-04:00', true);
};

module.exports = {
  crmFriendlyDate,
  dateAndTimeWithTimezoneCorrection,
};
