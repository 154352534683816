// Libs
import React, { useState, useEffect } from 'react';
import { has } from 'lodash';
//Assets.
import styles from './cookie-banner.module.scss';
import { cookieBanner } from '../../../locale/locale';
import { setUserCookie } from '../../../store/user/actions';
import { useStateValue } from '../../../store/state';

const CookieBanner = () => {
  let hideBannerTimeout;
  const [state, dispatch] = useStateValue();
  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    setBannerVisible(!has(state, 'user.cookie') || (has(state, 'user.cookie') && state.user.cookie !== true) ? true : false)
  }, [state.user]);
  const clickHandler = (cookie) => {
    setUserCookie(dispatch, cookie);
    if (hideBannerTimeout) {
      clearTimeout(hideBannerTimeout);
    }
  };

  if (bannerVisible) {
    hideBannerTimeout =  setTimeout(() => {
      setUserCookie(dispatch, true);
    }, 15000);
  }
  return (
    <>
      {bannerVisible && (
        <div className={styles.cookieBanner}>
          <div className={styles.cookieBannerContainer}>
            <p
              className={styles.cookieBannerMessage}
              dangerouslySetInnerHTML={{ __html: cookieBanner.message }}
            />
            <div className={styles.cookieBannerWrapper}>
              <button onClick={() => clickHandler(true)} className={styles.cookieBannerDismiss}>
                {cookieBanner.dismiss}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
