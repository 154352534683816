// Libs
const moment = require('moment');
const { get, assign, once, isObject, kebabCase, capitalize, keys, has, keyBy } = require('lodash');
// Supporting modules
const { dateAndTimeWithTimezoneCorrection } = require('./form/dates');

const todayAndBeyond = moment().add(30, 'minutes');
const logOne = once((el) => console.log(el));

/**
 * Returns a formatted drupal > local node for Class Category Taxonomy
 * @param node
 * @returns {{nid: *, id: *, title: *}}
 */
const formatClassCategory = (node) => {
  const newNode = {
    id: 0,
    tid: 0,
    title: '',
    parentId: null,
    weight: 0,
  };

  if (node) {
    Object.assign(newNode, {
      id: node.id,
      tid: node.drupal_internal__tid,
      title: node.name,
      parentId: isObject(node.parent) ? node.parent.drupal_internal__tid : null,
      weight: node.weight,
    });
  }

  return newNode;
};

/**
 * Returns a formatted drupal > local node for Instructor Taxonomy
 * @param node
 * @returns {{nid: *, id: *, title: *}}
 */
const formatInstructor = (node) => {
  const newNode = {
    id: 0,
    tid: 0,
    title: '',
    bioImage: {},
    sigImage: {},
  };

  if (node) {
    const bioImage =
      node.field_image && node.field_image.localFile
        ? node.field_image.localFile.childImageSharp
        : null;

    const sigImage =
      node.field_signature && node.field_signature.localFile
        ? node.field_signature.localFile.childImageSharp
        : null;

    Object.assign(newNode, {
      id: node.id,
      tid: node.drupal_internal__tid,
      title: node.name,
      bioImage,
      sigImage,
    });
  }

  return newNode;
};

/**
 * Returns a formatted drupal > local node for Ingredient Ctype
 * @param node
 * @returns {{nid: *, id: *, title: *}}
 */
const formatIngredient = (node) => {
  const newNode = {
    id: 0,
    nid: 0,
    title: '',
    image: {},
  };

  if (node) {
    const image =
      node.field_image && node.field_image.localFile
        ? node.field_image.localFile.childImageSharp
        : null;

    Object.assign(newNode, {
      id: node.id,
      nid: node.drupal_internal__nid,
      title: node.title,
      image,
    });
  }

  return newNode;
};

/**
 * Returns a formatted drupal > local node for Class Ctype
 * @param node
 * @returns {null|{duration: (*|string), forAdults: *, price: (*|number), nid: *, description: (*|string), id: *, loftId: (*|string), title: (*|string)}}
 */
const formatClassFromNode = (node) => {
  if (!node) {
    return {
      image: {},
      subCategories: [formatClassCategory()],
      ingredient: formatIngredient(),
    };
  }

  const { field_featured_image, field_sub_category } = node.relationships || {};

  const image =
    field_featured_image && field_featured_image.localFile
      ? field_featured_image.localFile.childImageSharp
      : null;

  const newNode = {
    id: node.id,
    metatag: node.metatag,
    nid: parseInt(node.drupal_internal__nid),
    title: node.title || '',
    price: parseFloat(node.field_price) || 0,
    loftId: parseInt(node.field_class_loft_id) || '',
    duration: node.field_duration || '',
    forAdults: node.field_for_adults,
    description: node.field_description || '',
  };

  assign(newNode, {
    image,
    subCategories: Array.isArray(field_sub_category) ? field_sub_category.map(formatClassCategory) : [],
    // ingredient: formatIngredient(field_ingredient),
  });

  // Pool the list of categories IDs for easy comparision filtering.
  newNode.subCategoryIds = keys(keyBy(newNode.subCategories, 'tid')) || [];
  // console.log('newNode.subCategoryIds', newNode.subCategoryIds);

  return newNode;
};

/**
 * Returns a formatted drupal > local node for Class Ctype
 * @param node
 * @returns {null|{withWine: *, availableSeats: (*|number), nid: *, wineCost: (*|number), title: (*|string), instructor: (*|null), price: (*|number), startTime: (*|string), id: *, endTime: (*|string), loftId: (*|string), classFormat: (*|string), startDate: (*|string)}}
 */
const formatClassScheduleFromNode = (node) => {
  if (!node) {
    return {
      class: formatClassFromNode(),
      instructor: formatInstructor(),
      store: formatStoreFromNode(),
    };
  }

  const { field_class, field_store, field_instructor } = node.relationships || {};

  const relationships = {
    class: formatClassFromNode(field_class),
    instructor: formatInstructor(field_instructor),
    store: formatStoreFromNode(field_store),
  };

  let startDateAndTime = dateAndTimeWithTimezoneCorrection(node.field_start_date, node.field_start_time);
  const newNode = {
    type: 'class',
    id: node.id,
    nid: parseInt(node.drupal_internal__nid),
    title: node.title || '',
    classFormat: capitalize(node.field_class_format || ''),
    loftId: parseInt(node.field_class_schedule_loft_id) || '',
    startDate: moment(startDateAndTime, 'YYYY-MM-DD h:mma').hour(2).minute(0),
    startDateAndTime: startDateAndTime,
    dateIsActive: startDateAndTime.isSameOrAfter(todayAndBeyond),
    startDateRaw: node.field_start_date,
    unixTimestamp: startDateAndTime.format('x'),
    startTime: node.field_start_time || '',
    endTime: node.field_end_time || '',
    availableSeats: parseInt(node.field_available_seats) || 0,
    availability: {
      apiLoaded: false,
      booking: true,
      seats: parseInt(node.field_available_seats) || 0,
      locations: 0,
      storeIds: [],
      relatedClasses: [],
    },
    price: parseFloat(node.field_price) || 0,
    wineCost: parseFloat(node.field_wine_cost) || 0,
    withWine: node.field_with_wine,
    locations: [],
    instructors: [],
    _siblings: {
      byClassNid: {},
      byDate: {},
      byDateTime: {},
    },
    // TODO: This field keeps coming empty from import... so exclude for now.
    // storeRelatedNotes: node.field_store_related_notes || '',
  };

  // Add relations
  assign(newNode, relationships);

  // Overrides from Class to Schedule entities.
  if (newNode.class) {
    const { title } = newNode.class || {};

    // Apply fallback fields where needed.
    assign(newNode, {
      alias: `/class/${kebabCase(title || newNode.title || node.drupal_internal__nid)}`,
      title: title || newNode.title,
    });
  }

  // if (newNode.class.image) {
  //   logOne(relationships);
  // }

  return newNode;
};

/**
 * Returns a formatted drupal > local node for Campaign Page Ctype
 * @param node
 * @returns {{nid: number, id: *, title: (*|string)}|null}
 */
const formatCampaignFromNode = (node) => {
  /*if (!node) {
    return {
      field_store_features: {},
      class: formatClassFromNode(),
    };
  }


  const relationships = {
    field_store_features,
    class: formatClassFromNode(field_class),
  };*/
  const { field_campaign_hero_image, field_campaign_products, field_campaign_department, campaignRecipes, field_campaign_featured_class, field_campaign_sign_up_image } = node.relationships || {};
  const products = [];
  const recipes = [];
  const department = {
    field: {
      title: get(field_campaign_department, "field_department_feature_heading.value", "" ) || "",
      subTitle: get(field_campaign_department, "field_department_feature_sub.value", "" ) || "",
      description: get(field_campaign_department, "field_department_feature_body", "" ) || "",
      link: {
        title: get(field_campaign_department,"field_department_feature_link.title", "" ) || "",
        uri: get(field_campaign_department,"field_department_feature_link.uri", "" ) || "",
      },
      image: formatFieldsRelationHelper(field_campaign_department.relationships.image || {}),
      portrait: formatFieldsRelationHelper(field_campaign_department.relationships.portrait || {}),
    }
  };

  field_campaign_products.forEach((product) => {
    products.push({
      title: get(product, "title" , "") || "",
      description: get(product, "description.value" , null) || null,
      price: get(product, "price.value" , null) || null,
      summary: get(product, "summary" , null) || null,
      bonus: get(product, "bonus.value" , null) || null,
      points: get(product, "points" , null) || null,
      image: formatFieldsRelationHelper(product.relationships.image || {})
    });
  })

  campaignRecipes.forEach((recipe) => {
    recipes.push({
      title: recipe.title,
      nid: recipe.nid,
      total: recipe.total,
      image: formatFieldsRelationHelper(recipe.relationships.image || {})
    });
  })

  const internalURL = node.campaign_url.uri.split("/");
  const newNode = {
    id: node.id,
    nid: parseInt(node.drupal_internal__nid),
    title: node.title || '',
    name: node.title || '',
    url: "/"+internalURL.slice(1,internalURL.length).join("/"),
    hero: {
      image: formatFieldsRelationHelper(field_campaign_hero_image),
      subTitle: node.campaign_dates.value,
      title: node.hero_title.value,
      body: node.hero_description.value,
      publish: node.hero_publish
    },
    testArea_1: {
      publish: node.field_campaign_show_text_1,
      title: node.field_campaign_message_title_1,
      body: node.field_campaign_message_body_1.value,
    },
    testArea_2: {
      publish: node.field_campaign_show_text_2,
      title: node.field_campaign_message_title_2,
      body: node.field_campaign_message_body_2.value,
    },
    testArea_3: {
      publish: node.field_campaign_show_text_3,
      title: node.field_campaign_message_title_3,
      body: node.field_campaign_message_body_3.value,
    },
    testArea_4: {
      publish: node.field_campaign_show_text_4,
      title: node.field_campaign_message_title_4,
      body: node.field_campaign_message_body_4.value,
    },
    testArea_5: {
      publish: node.field_campaign_show_text_5,
      title: node.field_campaign_message_title_5,
      body: node.field_campaign_message_body_5.value,
    },
    products: {
      publish: node.field_publish_campaign_products,
      products: products
    },
    cta: {
      publish: node.field_publish_campaign_cta,
      title: node.field_campaign_cta_title.value,
      linkFindStore: {
        title: node.field_campaign_find_a_store.title,
        uri: node.field_campaign_find_a_store.uri
      },
      linkShopOnline: {
        title: node.field_campaign_shop_online.title,
        uri: node.field_campaign_shop_online.uri
      }
    },
    department: {
      publish: node.field_campaign_publish_dpt,
      ...department
    },
    featuredClass: {
      publish: node.field_publish_cmp_featured_class,
      field: {
        nid: field_campaign_featured_class.drupal_internal__nid
      }
    },
    recipes: {
      publish: node.field_publish_campaign_recipes,
      recipes: recipes,
      title: node.field_campaign_recipes_label.value
    },
    signUp: {
      publish: node.field_publish_campaign_signup,
      title: node.field_campaign_sign_up_title.value,
      description: node.field_campaign_sign_up_descripti.value,
      link: {
        title: node.field_campaign_sign_up_link.title,
        uri: node.field_campaign_sign_up_link.uri,
      },
      image: formatFieldsRelationHelper(field_campaign_sign_up_image),
    }
  };

  // Add relations
  // assign(newNode, relationships);

  return newNode;
};

/**
 * Returns a formatted drupal > local node for Store Ctype
 * @param node
 * @returns {{nid: number, id: *, title: (*|string)}|null}
 */
const formatStoreFromNode = (node) => {
  if (!node) {
    return {
      field_store_features: {},
      class: formatClassFromNode(),
    };
  }

  const { field_store_features, field_class } = node.relationships || {};

  const relationships = {
    field_store_features,
    class: formatClassFromNode(field_class),
  };

  const newNode = {
    ...node,
    id: node.id,
    nid: parseInt(node.drupal_internal__nid),
    loftId: parseInt(node.field_store_loft_id) || '',
    crmId: parseInt(node.field_crm_store_id) || '',
    storeHidden: node.field_store_hidden_from_website || false,
    title: node.title || '',
    name: node.title || '',
    address1: node.field_store_address ? node.field_store_address.address_line1 : '',
    city: node.field_store_address ? node.field_store_address.locality : '',
    province: node.field_store_address ? node.field_store_address.administrative_area : '',
    postalCode: node.field_store_address ? node.field_store_address.postal_code : '',
    alias: `/store/${kebabCase(node.title || node.drupal_internal__nid)}`,
    flyerTitle: node.field_store_flyer_title || '',
    flyerBody: node.field_store_flyer_body || '',
    latitude: node.field_store_latitude || '',
    longitude: node.field_store_longitude || '',
    parking: node.field_store_parking || '',
    accessibleBathrooms: node.field_store_accessible_bathrooms || '',
    accessibleEntrance: node.field_store_accessible_entrance || '',
    phone: node.field_store_phone || '',
    pharmacyPhone: node.field_store_pharmacy_phone || '',
    storeHours: node.field_store_open_hours || [],
    textBox: node.field_store_text_box || '',
    lon: node.field_store_longitude || '',
    lat: node.field_store_latitude || '',
  };

  // if (newNode.nid === 472) {
  //   console.log(`Store ${newNode.nid}`, field_class, node.relationships);
  // }

  // Add relations
  assign(newNode, relationships);

  return newNode;
};

const getRecipeTitleAndAlias = (node) => {
  const { title } = node.class || {};
  // Apply fallback fields where needed.
  return {
    alias: `/recipe/${kebabCase(title || node.title || node.nid)}`,
    title: title || node.title,
  };
}

/**
 * Returns a formatted drupal > local node for Store Ctype
 * @param node
 * @returns {{nid: number, id: *, title: (*|string)}|null}
 */
const formatRecipeFromNode = (node) => {
  if (!node) {
    return {};
  }

  const newNode = {
    ...node,
    type: 'recipe',
  };
  // Apply fallback fields where needed.
  assign(newNode, getRecipeTitleAndAlias(newNode));
  return formatFieldsValue(newNode);
};


const formatFieldsValueHelper = (field) => {
  if (keys(field).length === 1 && has(field, 'value')) {
    return field.value;
  }
  return field;
};

const formatFieldsRelationHelper = (field) => {
  return get(field, 'localFile.childImageSharp', field);
};

const formatFieldsValue = (fields) => {
  let data = formatFieldsValueHelper(fields);
  if (isObject(data)) {
    if (has(data, 'relationships')) {
      const { relationships } = data;
      delete data.relationships;
      data = { ...data, ...relationships };
    }
    data = formatFieldsRelationHelper(data);
    for (const field in data) {
      data[field] = formatFieldsValue(data[field]);
    }
  }
  return data;
};

const formatFMK = (node) => {
  const card = formatNode(node);
  let earlyPrice = card.price;
  if (card.earlyBird) {
    const today = moment();
    if (today.isBetween(moment(card.dateRange.start, 'YYYY-MM-DD'), moment(card.dateRange.end, 'YYYY-MM-DD'))) {
      earlyPrice = card.earlyBird;
    }
  }
  card.currentPrice = earlyPrice;
  return card;
};

/**
 * Returns a formatted drupal > local node for Ctype
 * @param node
 * @returns {{nid: number, id: *, title: (*|string)}|null}
 */
const formatNode = (node) => {
  if (!node) {
    return {};
  }

  const newNode = {
    ...node,
  };
  const { title } = newNode.class || {};
  // Apply fallback fields where needed.
  assign(newNode, {
    title: title || newNode.title,
  });
  return formatFieldsValue(newNode);
};

module.exports = {
  logOne,
  formatClassCategory,
  formatIngredient,
  formatInstructor,
  formatClassFromNode,
  formatClassScheduleFromNode,
  formatCampaignFromNode,
  formatStoreFromNode,
  formatFieldsValue,
  formatNode,
  formatRecipeFromNode,
  formatFMK,
  getRecipeTitleAndAlias,
};
