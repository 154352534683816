// Libs.
import React from 'react';
import Img from 'gatsby-image';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import menus from '../../mocks/footer-menus';
import FooterMenu from '../footer/footer-menu';
import FooterSubscribe from '../footer/footer-subscribe';
// Assets.
import styles from './footer.module.scss';
import FooterSocialIcons from '../footer/footer-social-icons';
import useMenuFooterItemsStaticQuery from '../../hooks/useMenuFooterItemsStaticQuery';
import useMenuFooterSecondaryItemsStaticQuery from '../../hooks/useMenuFooterSecondaryItemsStaticQuery';
import FooterAccessibilityIcon from '../footer/footer-accessibility-icon';

export default () => {
  const logo = useImageStaticQuery('Longos-white.svg');
  const logo1 = useImageStaticQuery('Best-Managed.svg');
  const logo2 = useImageStaticQuery('Most-Admired.svg');

  const firstMenu = useMenuFooterItemsStaticQuery();
  const secondMenu = useMenuFooterSecondaryItemsStaticQuery();

  return (
    <section className={styles.footer}>
      <div className={styles.footerContainer}>
        {!logo.childImageSharp ? (
          <img src={logo.publicURL} className={styles.footerLogo} alt={'Longos'} />
        ) : (
          <Img fluid={logo.childImageSharp.fluid} />
        )}
        <hr />
        <div className={styles.footerMenusAndEmail}>
          <div className={styles.footerMenus}>
            <FooterMenu items={firstMenu} />
            <FooterMenu items={secondMenu} />
          </div>
          <div>
            <FooterSubscribe />
            <div className={styles.footerAccessibilityMobile}>
              <FooterAccessibilityIcon />
            </div>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.footerSocial}>
            <FooterSocialIcons items={menus.social} />
          </div>
          <div className={styles.footerCopyright}>
            Copyright {(new Date()).getFullYear()} Longo Brothers Fruit Markets Inc. All rights reserved.
          </div>
          <div className={styles.footerAccessibility}>
            <FooterAccessibilityIcon />
          </div>
          <div className={styles.footerLogos}>
            <img src={logo1.publicURL} alt="logo" />
            <img src={logo2.publicURL} alt="logo" />
          </div>
        </div>
      </div>
    </section>
  );
};
