import moment from 'moment';
import { divmod } from './math';
import { padStart } from 'lodash';

/**
 * Converts 24 hours time to 12 hours format.
 *
 * @param {number} time
 *   Time in 24 hours.
 * @param {number} minutes
 *   Optional minutes indicator
 *
 * @returns {string} Time in 12 hours format.
 */
export function transformTo12(time, minutes) {
  let h = time % 12;
  if (h === 0) {
    h = 12;
  }
  minutes = minutes || '00';

  return (h < 10 ? '' : '') + h + `:${minutes}` + (time < 12 ? 'am' : 'pm');
}

export function hourDifference(startTime, endTime) {
  const start = moment(startTime, 'h:mma');
  const end = moment(endTime, 'h:mma');
  const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss'));
  const d = moment.duration(ms);
  return Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
}

export function minutesToFormattedLabel(duration) {
  const [hours, minutes] = divmod(duration, 60);
  let classLength = '';

  // Only set a label if we have a valid time results.
  if (hours > 0 || minutes > 0) {
    classLength = `${hours >= 1 ? hours + ':' : ''}${hours > 0 ? padStart(minutes, 2, '0') : minutes}`;
  }

  // console.log('hours', {
  //   duration,
  //   hours,
  //   minutes,
  //   classLength,
  // });

  return [hours, minutes, classLength];
}

/**
 * Transform minutes to milliseconds.
 *
 * @export
 * @param {Number} mins - Minutes.
 * @returns {Number} - Milliseconds.
 */
export function minToMillsec(mins) {
  return mins * 60000;
}
