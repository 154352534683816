import ActionTypes from './action-types';

// Opt-In for member sign up.
export const SetMemberOptIn = (value) => ({
  type: ActionTypes.OPTIN_MEMBER,
  payload: value,
});

// Opt-In for terms and conditions.
export const SetTermsOptIn = (value) => ({
  type: ActionTypes.OPTIN_TERMS,
  payload: value,
});

// Set guest checkout
export const GuestCheckout = (value) => ({
  type: ActionTypes.GUEST_CHECKOUT,
});

// Reset guest checkout mode
export const ResetGuestCheckout = (value) => ({
  type: ActionTypes.RESET_GUEST_CHECKOUT,
});

// Update the checkout customer data.
export const UpdateCheckoutCustomer = (customer) => ({
  type: ActionTypes.UPDATE_CHECKOUT_CUSTOMER,
  payload: customer,
});
// Update the checkout customer data.
export const ResetCheckoutCustomer = () => ({
  type: ActionTypes.RESET_CHECKOUT_CUSTOMER,
});

// Complete order.
export const CompleteOrder = (order) => ({
  type: ActionTypes.COMPLETE_ORDER,
  payload: order,
});
