// Libs
import React, {useState, useEffect, useRef} from 'react';
import { defaults } from 'lodash';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
// State
import { useStateValue } from '../../store/state';
import { modalStatuses, modalTypes } from '../../store/modal/action-types';
import { useLockBodyScroll } from '../../hooks/useBodyScrollable';
// Assets
import styles from './modal.module.scss';

const initialState = {
  id: null,
  anchorEl: null,
  content: null,
  type: null,
  className: styles.modalContainer,
  animateInClass: null,
  displayed: false,
  lockBody: false,
};

const Modal = ({isApp=false}) => {
  const [
    {
      modal,
      modal: { status, type, id, content, anchorEl, offsetPos },
    },
  ] = useStateValue();

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      id,
      anchorEl,
      content,
      type,
      offsetPos: defaults(offsetPos, { top: 0, left: 0 }),
      className: type === modalTypes.FILTER ? styles.modalFloatingContainer : styles.modalContainer,
      animateInClass: type === modalTypes.SLIDEIN && styles.modalContainerSwipeInLeft,
      displayed: status === modalStatuses.OPEN && !!content,
      lockBody: !(type === modalTypes.FILTER && document.body.clientWidth > 640),
    });
    // console.log('modal state', state);
  }, [modal]);

  return state.displayed && <ActiveModal isApp={isApp} state={state} />;
};

export const ActiveModal = ({isApp,
  state: { id, className, type, anchorEl, lockBody, animateInClass, content, offsetPos, displayed },
}) => {
  const windowPos = useWindowScrollPosition({
    throttle: type === modalTypes.FILTER ? 2 : 60000,
  });
  const [styling, setStyling] = useState();
  const modalContainer = useRef(null);

  // See if we're locking the body here
  useLockBodyScroll(lockBody);

  const reviseStyling = () => {
    const newStyling = {};

    if (anchorEl) {
      const { top, left } = anchorEl.getBoundingClientRect();

      Object.assign(newStyling, {
        top: `${parseInt(top) + offsetPos.top}px`,
        left: `${parseInt(left) + offsetPos.left}px`,
      });
    }

    setStyling(newStyling);

    // console.log('activeModal restyle', windowPos, newStyling);
  };

  // console.log('activeModal opening',flyer-fullsize--column id, windowPos);

  useEffect(() => {
    const requestId = window.requestAnimationFrame(reviseStyling);
    return () => {
      // console.log('activeModal cleanup');
      window.cancelAnimationFrame(requestId);
    };
  }, [anchorEl, windowPos]);

  useEffect(() => {
    // Focus when is displayed so tab navigation moves over the modal elements
    // for accesibility purposes.
    if (displayed) {
      modalContainer.current.focus();
    }
  }, [displayed]);

  return (
    <div id={id} className={className ? className : undefined} style={styling} data-app={isApp}>
      <div tabIndex="0" ref={modalContainer} className={animateInClass ? animateInClass : undefined}>
        {content}
      </div>
    </div>
  );
};

export default Modal;
