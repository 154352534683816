// Libs.
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
// Assets.
import cartFilledIcon from '../../../svgs/icons/icon-cart-filled.svg';
// State.
import { useStateValue } from '../../store/state';
import {map, sum} from 'lodash';

const Cart = ({ className }) => {
  const [state] = useStateValue();
  const {cart} = state;
  const count = sum(map(cart, x => x.lineItemsCount));
  // Effect when cart lineItems change.
  useEffect(() => {}, [count]);

  if (!count) {
    return false;
  }

  return (
    <Link className={className} to="/cart">
      <img src={cartFilledIcon} alt="My Cart" />
    </Link>
  );
};

Cart.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Cart;
