import getWindow from './getWindow';

/**
 * A helper function to determine whether we are viewing the app on iPhone or safari.
 * @param path
 * @returns {Window}
 */

export function getViewingPlatform() {
  const navigator = getWindow('navigator');
  const window = getWindow();
  const isSafari = navigator && !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  const iOS = navigator && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const tallDevice = window.innerWidth / window.innerHeight <= 9/18;

  return { isSafari, iOS, tallDevice };
}
