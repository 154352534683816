import ActionTypes from './action-types';

// Body un-scrollable action.
export const uiBodyUnscrollable = () => ({
  type: ActionTypes.BODY_UNSCROLLABLE,
  payload: {
    scrollable: false,
  },
});
export const uiBodyScrollable = () => ({
  type: ActionTypes.BODY_SCROLLABLE,
  payload: {
    scrollable: true,
  },
});
