// Libs.
import { useEffect } from 'react';
// State
import { useStateValue } from '../store/state';
import { CustomerSetMyStore } from '../store/customer/action-creators';
import { UserSetDefaultStore } from '../store/user/action-creators';
// Custom Libs.
import { storeDrupalId, storeCrmId } from '../libs/store/store-utils';
// import { getNearestStore, storeDrupalId, storeCrmId } from '../libs/store/store-utils';
import { addNotification } from '../libs/notify';
// Services.
import crmClient from '../services/crmClientService';
import { useStores } from '../services/storesIndexService';

// TODO: Longos needs to define what's the default store to fallback
// when user location is not allowed to use.
// const DEFAULT_STORE_DRUPAL_ID = 474;

export function useDefaultStore() {
  const storesIndex = useStores();
  const [state, dispatch] = useStateValue();
  const { CRMCustomerID, access_token, defaultStoreId } = state.user;
  const { PreferredStoreNumber } = state.customer;
  const customerStore = storesIndex.getByCrmId(PreferredStoreNumber);
  const guestStore = storesIndex.getByDrupalId(defaultStoreId);
  // Select the preferred store by relevance order: 1) The CRM preferred store.
  // 2) Local state selected store. Ensure that store ID lookup (above) resolves
  // within the GraphQL stores collection to avoid store utility components
  // display empty in case relationship is broken.
  const savedStoreId =
      parseInt(storeDrupalId(customerStore)) || parseInt(storeDrupalId(guestStore)) || defaultStoreId || null;

  // Effect when user geolocation changed due to successful determined location
  // or any error: (denied or ignored).
  useEffect(() => {
    const userLocation = {
      latitude: state.user.location.latitude,
      longitude: state.user.location.longitude,
    };

    if (userLocation.latitude && userLocation.longitude && defaultStoreId === null) {
      // const nearestStore = getNearestStore(storesIndex.getAll(), userLocation);
      // dispatch(UserSetDefaultStore(storeDrupalId(nearestStore)));
    } else {
      if (!!defaultStoreId) {
        dispatch(UserSetDefaultStore(defaultStoreId));
      }
    }

    // When no available preferred store due not set or location not available
    // default to defined DEFAULT_STORE_LOFT_ID.
    if (state.user.location.error && defaultStoreId === null) {
      // dispatch(UserSetDefaultStore(DEFAULT_STORE_DRUPAL_ID));
    }
  }, [state.user.defaultStoreId, state.user.location.timestamp, state.user.location.error]);

  return {
    storeId: savedStoreId,
    nearestStoreId: defaultStoreId,
    setStore: (storeId) => {
      // Only for anonymous users update defaultStoreId user state value.
      if (access_token) {
        const newMyStore = storesIndex.getByDrupalId(storeId);
        const newMyStoreName = newMyStore.name;
        const customerUpdate = {
          PreferredStoreNumber: storeCrmId(newMyStore),
        };

        // Save new preferred store ID into CRM customer entity.
        crmClient
          .customerUpdate(CRMCustomerID, customerUpdate, access_token)
          .then((data) => {
            // Set new store into customer state.
            dispatch(CustomerSetMyStore(storeCrmId(newMyStore)));
            addNotification(`${newMyStoreName} was saved as your preferred store.`);
          })
          .catch((data) => {
            // TODO: Display as failed message, when messages component support
            // error message type.
            addNotification(` There was an error updating your preferred store, please try again.`);
          });
      } else {
        dispatch(UserSetDefaultStore(storeId));
      }
    },
  };
}
