import getWindow from './getWindow';
/**
 * A safe convenient way to obtain the window, or part of it, in gatsby DOM without build errors.
 * @param path
 * @returns {*}
 */
export function pushEvent(event, eventCategory, eventAction, eventLabel='') {
  if(!getWindow().dataLayer) {
    return;
  }
  getWindow().dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel
  })
}
