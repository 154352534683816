import moment from 'moment';
import { divmod } from './math';
import { has } from 'lodash';

/**
 * Find today hours for a given store object.
 *
 * @param {object} store
 *   The store object.
 *
 * @returns {object} The store today hours.
 */
function storeTodayHours(store) {
  const date = new Date();
  const fieldStoreOpenHours = store.field_store_open_hours;
  const results =
    Array.isArray(fieldStoreOpenHours) &&
    fieldStoreOpenHours.filter((entry) => entry.day === date.getDay())[0];
  let holidays = [];
  if (has(store, 'relationships.field_holidays')) {
    holidays = store.relationships.field_holidays;
  }
  const holiday = holidays.find((holiday) => {
    return moment(holiday.date, 'YYYY-MM-DD').isSame(moment(), 'day');
  });

  if (holiday && results) {
    results.starthours = holiday.time.from / 36;
    results.endhours = holiday.time.to / 36;
  }
  return results || {};
}

/**
 *  Current store opening hours based on current date and time.
 *
 * @param store
 * @param isApp
 * @returns {string}
 */
export function storeOpenHours(store, isApp = false) {
  let result = `Currently closed`;
  const { starthours, endhours } = storeTodayHours(store);

  if (endhours) {
    const now = moment();
    const storeOpen = moment();
    const storeClose = moment();
    const [startHour, startMinutes] = divmod(starthours, 100);
    const [endHour, endMinutes] = divmod(endhours, 100);
    storeOpen.seconds(0);
    storeOpen.minutes(startMinutes);
    storeOpen.hour(startHour);
    storeClose.seconds(0);
    storeClose.minutes(endMinutes);
    storeClose.hour(endHour);

    if (now.isBefore(storeOpen)) {
      // If we're early morning, and the store isn't open yet
      // result = `Opening at ${storeOpen.format('h:mma')}`;
      result = `Closed`;
    } else if (now.isAfter(storeOpen) && now.isBefore(storeClose)) {
      // If the store is open and note yet closed
      result = `Open until ${storeClose.format('h:mma')}`;
    } else if (now.isAfter(storeClose)) {
      // If the store has closed for the day
      // result = `Closed at ${storeClose.format('h:mma')}`;
      result = `Closed`;
    }
  }

  return result;
}
