// Libs.
import React, { useEffect, useState } from 'react';
// Deps.
import Logo from './logo';
import Cart from './cart';
import PrimaryMenu from '../primary-menu/primary-menu';
import UtilityMenu from '../utility-menu/utility-menu';
import Message from '../common/message/message';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Hooks.
import { useNavActive } from '../../hooks/useNavActive';
// Assets.
import styles from './navbar.module.scss';
import { pushEvent } from '../../libs/dataLayer';

export default () => {
  const [eventsBound, setEventsBound] = useState(false);
  const { isActive, toggle } = useNavActive();

  const handleResize = () => {
    // IF the user was on mobile view with the navbar open, and goes to desktop, flag the mobile menu as closed.
    if (window.innerWidth > 800) {
      toggle(false);
    }
  };

  const unbindResize = () => {
    setEventsBound(false);
    window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    if (!eventsBound && isActive) {
      setEventsBound(true);
      window.addEventListener('resize', handleResize);
      pushEvent('Interaction', 'Menu', `Mobile Menu opened`);
    } else if (!isActive) {
      unbindResize();
      pushEvent('Interaction', 'Menu', `Mobile Menu closed`);
    }

    // console.log('navbar', { eventsBound, isActive });

    return unbindResize;
  }, [isActive]);

  const handleToggle = (e) => {
    toggle();
  };

  return (
    <nav className={styles.navbar} data-active={isActive}>
      <div className={styles.navbarBrand}>
        <div
          role="button"
          tabIndex="0"
          className={styles.navbarIcon}
          onClick={handleToggle}
          onKeyPress={redirectKeyPressHandler(handleToggle)}
          aria-label="Navigation bar"
        >
          <span className={styles.navbarIconBar}> </span>
          <span className={styles.navbarIconBar}> </span>
          <span className={styles.navbarIconBar}> </span>
          <span className={styles.navbarIconBar}> </span>
        </div>
        <Logo />
        <Cart className={styles.navbarCartIcon} />
      </div>
      <div className={styles.navbarMenuWrapper}>
        <UtilityMenu />
        <PrimaryMenu />
      </div>
      <Message />
    </nav>
  );
};