import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconStores = (props) => {
  const styles = {
    colorPrimary: "#414141"
  };
  return (
    <SvgIcon {...props} viewBox="0 0 16 24" classes={styles}>
      <g fill="#414141">
        <path id="a" d="M.993 7.845c0 2.443 2.456 6.46 7.007 11.605 4.55-5.147 6.994-9.163 6.994-11.605C14.994 4.154 11.782 1 8 1 4.218 1 .993 4.154.993 7.845zM8 21l-.424-.473C2.478 14.845 0 10.697 0 7.845 0 3.52 3.59 0 8 0s8 3.52 8 7.845c0 2.85-2.478 6.999-7.576 12.682L8 21zM7.993 9.5c-.96 0-1.737-.784-1.737-1.75S7.033 6 7.993 6c.96 0 1.738.784 1.738 1.75S8.953 9.5 7.993 9.5z"/>
      </g>
    </SvgIcon>
  );
}

export default IconStores;
