import ActionTypes from './action-types';

// User login action creation.
export const UserLoginComplete = (session) => ({
  type: ActionTypes.LOGIN,
  payload: session,
});

// User logout action creation.
export const UserLogoutComplete = (session) => ({
  type: ActionTypes.LOGOUT,
});

// User logout action creation.
export const UserPasswordReset = () => ({
  type: ActionTypes.RESET,
});

// User set location action creation.
export const UserSetLocation = (location) => ({
  type: ActionTypes.SET_LOCATION,
  payload: location,
});

// User set default store (used by proximity).
export const UserSetDefaultStore = (storeId) => ({
  type: ActionTypes.SET_STORE,
  payload: {
    defaultStoreId: parseInt(storeId),
  },
});

// User set Cookie.
export const UserSetCookie = (cookie) => ({
  type: ActionTypes.SET_COOKIE,
  payload: {
    cookie: cookie,
  },
});

// User set default store (used by proximity).
export const UserSuggestedStore = (once, storeId) => ({
  type: ActionTypes.SET_SUGGESTED_STORE,
  payload: {
    suggestedStore: {
      once: once,
      storeId: parseInt(storeId),
    }
  },
});
