import { isFunction } from 'lodash';
import { navigate as navigateFn } from 'gatsby';
import getWindow from './getWindow';

const navigate = (path, options) => {
  const nav = getWindow('___navigate');
  if (isFunction(navigateFn) && nav && path) {
    navigateFn(path, options);
  }
};

export default navigate;
