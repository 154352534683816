// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Assets.
import styles from './content-teaser.module.scss';
// Hooks.
import { useMenuActive } from '../../hooks/useMenuActive';
import SiteLink from '../common/site-link';

const ContentTeaser = ({ menuItems }) => {
  const menuActive = useMenuActive();
  const { activeParent } = menuActive;
  const handleTab = (e) => {
    const key = e.key || e.which || e.charCode || e.code;
    if ((key === 'Tab' || key === 9) && document && !e.shiftKey) {
      document.querySelector('[class*="primary-menu-bar"] > a:first-of-type').focus();
    }
  };
  // Find parent item object.
  const parentMenuItem = menuItems.find((item) => {
    return item.node.drupal_id === activeParent;
  });

  // Skip rendering when parent item not found.
  if (!parentMenuItem) {
    return null;
  }

  const { node } = parentMenuItem || {};

  // Skip render if teaser content display is disabled.
  if (!node.field_teaser_display) {
    return null;
  }

  return (
    <div className={styles.contentTeaser}>
      <div className={styles.contentTeaserBody}>
        <h2 className={styles.contentTeaserTitle}>{node.field_teaser_heading}</h2>
        <p
          className={styles.contentTeaserSummary}
          dangerouslySetInnerHTML={{ __html: node.field_teaser_body.processed }}
        />

        <SiteLink
          className={styles.contentTeaserLink}
          href={node.field_teaser_link.uri}
          data-change-focus="1"
          onKeyDown={(e) => handleTab(e)}
        >
          {node.field_teaser_link.title || 'Read more'}
        </SiteLink>
      </div>
      {node.relationships.field_teaser_image.localFile && (
        <Img
          className={styles.contentTeaserImage}
          fluid={node.relationships.field_teaser_image.localFile.childImageSharp.fluid}
        />
      )}
    </div>
  );
};

ContentTeaser.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default ContentTeaser;
