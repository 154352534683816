// Libs
import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import { CloseModal } from '../../store/modal/action-creators';
// Deps
import Modal from './modal';
// Assets
import styles from './AppLayout.module.scss';
import { useStateValue } from '../../store/state';
import { uiBodyScrollable } from '../../store/ui/action-creators';
import AppNavigation from './AppNavigation';
// Services.
import crmClient from '../../services/crmClientService';
import Message from "../common/message/message";
import {AppNotify} from "./notifyContext";
// Hooks.
import useInterval from "../../hooks/useInterval";
import useCustomer from "../../hooks/useCustomer";
import useAuthenticationExpire from '../../hooks/useAuthenticationExpire';
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';

import { siteOriginIs, siteTypes } from '../../libs/api';
import navigate from '../../libs/navigate';

export const AppContext = React.createContext(false);

const AppLayout = ({ children, containered, menu = true, updatePoints = false }) => {
  // Trigger classes static query at Layout (common component) so Gatsby assumes
  // the result data as part of common JS files for code splitting, ensuring
  // that Loft class objects array is loaded once in mobile app, see:
  // https://www.gatsbyjs.org/docs/how-code-splitting-works



  useClassesStaticQuery();
  // Remove modals from other pages.
  const [, dispatch] = useStateValue();
  const [notify, setNotify] = useState(null);
  const { getCustomerPoints } = useCustomer();
  crmClient.useAppHeaders();
  useEffect(() => {
    dispatch(uiBodyScrollable());
    dispatch(CloseModal());
  }, []);
  useInterval(updatePoints && getCustomerPoints, 5000);
  useAuthenticationExpire(true);

  // If the current site is not an app site, kill app layout. Must check for bool, as -1 can mean this is SSR.
  if (siteOriginIs(siteTypes.app, 'localhost') === false) {
    navigate('/');
    return null;
  }

  return (
    <>
      <AppContext.Provider value={true}>
        <AppNotify.Provider value={[notify, setNotify]}>
          <Message isApp={true} />
          <div className={styles.layoutWrapper} data-menu={menu}>
            <div className={containered ? styles.container : ''}>
              <main className={styles.mainContainer}>{children}</main>
            </div>
            {menu && <AppNavigation />}
          </div>
          <Modal isApp={true} />
        </AppNotify.Provider>
      </AppContext.Provider>
    </>
  );
};

AppLayout.defaultProps = {
  children: null,
  containered: false,
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containered: PropTypes.bool,
};

export default AppLayout;
