// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
// Deps.
import ContentTeaser from '../content/content-teaser';
import { useMenuActive } from '../../hooks/useMenuActive';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Assets.
import styles from './sub-menu.module.scss';
import SiteLink from '../common/site-link';

const SubMenu = ({ menuItems }) => {
  const menuActive = useMenuActive();
  const { activeParent } = menuActive;

  // Handle navigate to menu link.
  const handleGoTo = (e) => {
    e.stopPropagation();
  };

  const renderSecondaryMenuItems = () => {
    const parent = `menu_link_content:${activeParent}`;
    const subitems = menuItems.filter((item) => item.node.fields.parent_menu_id === parent);
    return (
      <>
        <ul>
          {subitems.map((item) => (
            <li key={item.node.drupal_id}>
              <SiteLink className={styles.subMenuItemLink} href={item.node.link.uri} onClick={handleGoTo}>
                {item.node.title}
              </SiteLink>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className={styles.subMenuOverlay} data-visibility={activeParent ? 'visible' : 'hidden'}>
      <div className={styles.subMenu}>
        <div
          role="button"
          tabIndex="0"
          className={styles.subMenuMobileHeader}
          onClick={() => menuActive.closeSubmenu()}
          onKeyPress={redirectKeyPressHandler(menuActive.closeSubmenu)}
        >
          <span className={styles.subMenuTitle}>MENU</span>
        </div>
        <div className={styles.subMenuHeader}>
          <h3 className={styles.subMenuTitle}>In this section</h3>
          <div className={styles.subMenuClose}>
            <h3
              role="button"
              tabIndex="0"
              className={styles.subMenuCloseLabel}
              onClick={() => menuActive.closeSubmenu()}
              onKeyPress={redirectKeyPressHandler(menuActive.closeSubmenu)}
            >
              Close
            </h3>
          </div>
        </div>
        <div className={styles.subMenuContentWrapper}>
          <div onClick={menuActive.preventClose} className={styles.subMenuContent}>
            <div className={styles.subMenuItems}>{renderSecondaryMenuItems()}</div>
            <ContentTeaser menuItems={menuItems}/>
          </div>
        </div>
      </div>
    </div>
  );
};

SubMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default SubMenu;
