// Libs
import React, { useRef, useEffect } from 'react';
import { kebabCase, noop } from 'lodash';
// Assets
import styles from './form-input.module.scss';

const FormInput = ({ state, ...data }) => {
  const _ref = useRef();
  const fieldName = data.name || kebabCase(data.placeholder);
  // Check for Aria Label, and use fall backs as needed to fill the field.
  const ariaLabel = data['aria-label'] ?
    data['aria-label'] :
    (data.placeholder ?
      data.placeholder :
      data.name);

  // Default to noop when nothing is passed.
  let onChange = data.onChange || noop;
  const input = data.formRef ? data.formRef : _ref;
  const className = `${data.className} ${styles.formInput}`;

  if (!data.type) {
    data.type = 'text';
  }

  if (state) {
    data.value = state.value.get;
    data.error = state.error.get;

    if (state.valid) {
      data.valid = state.valid.get;
    }

    onChange = (e) => {
      const value = e.target.value;
      state.value.set(value);
      state.error.set(data.validator(value));
      // console.log('FormInput', value, data.validator(value), state);
    };
  }

  useEffect(() => {
    const { current } = input;
    if (data.disabled) {
      current.setAttribute('disabled', 'disabled');
    } else {
      current.removeAttribute('disabled');
    }
  }, [data]);

  return (
    <div className={className} data-error={!!data.error} data-valid={data.valid} id={data.id}>
      <label>{data.label}</label>
      {data.type !== 'select' && (
        <input
          autoCapitalize="none"
          ref={input}
          placeholder={data.placeholder}
          required={data.required}
          onChange={onChange}
          onBlur={data.onBlur}
          name={fieldName}
          type={data.type}
          value={data.value}
          maxLength={data.maxLength}
          data-error={!!data.error}
          aria-label={ariaLabel}
          onFocus={data.onFocus}
        />
      )}
      {data.type === 'select' && (
        <select
          ref={input}
          placeholder={data.placeholder}
          required={data.required}
          onChange={onChange}
          onBlur={data.onBlur}
          name={fieldName}
          defaultValue={data.value}
          aria-label={ariaLabel}
        >
          {data.options.map((option) => (
            <option
              key={option.value} value={option.value}
              defaultValue={!data.value && data.defaultValue === option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      )}
      <div className={styles.formInputError}>{data.error && data.errorMsg}</div>
      {data.valid && <div className={styles.formInputValid}>{data.validMsg}</div>}
    </div>
  );
};

export default FormInput;
