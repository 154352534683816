import { useToggle } from '../hooks/useToggle';
import { useStateValue } from '../store/state';
import { uiBodyScrollable, uiBodyUnscrollable } from '../store/ui/action-creators';

export const useNavActive = () => {
  const { isActive, toggle } = useToggle();
  const [, dispatch] = useStateValue();

  const handleToggle = (forced) => {
    // Condition is based on state prior the toggle so dispatch actions are
    // based on current to new toggle state transition.
    toggle(forced) ? dispatch(uiBodyScrollable()) : dispatch(uiBodyUnscrollable());

    // console.log('handleToggle', { isActive, forced });
  };

  return {
    isActive: isActive,
    toggle: handleToggle,
  };
};
