import React from 'react';
import { Link } from 'gatsby';
import styles from './logo.module.scss';
import Logo from '../../images/longos-logo.svg';

export default () => (
  <Link to="/" className={styles.logo}>
    <img src={Logo} className={styles.logoImage} alt="Longos" />
  </Link>
);
