// Libs.
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
// Custom Libs.
import { storeDrupalId } from '../../libs/store/store-utils';
import { storeOpenHours } from '../../libs/storeOpenHours';
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Hooks.
import { useDefaultStore } from '../../hooks/useDefaultStore';
// Assets
import styles from './utility-store-dropdown.module.scss';

const UtilityStoreDropdown = ({ store, isActive, setActive, suggestNearest }) => {
  const isSuggestNearest = !!suggestNearest ? suggestNearest : false;
  const [eventsBound, setEventsBound] = useState(false);
  const { storeId, setStore } = useDefaultStore();
  const { name, address1, city, phone } = store;
  const addressAndCity = `${address1}, ${city}, CA`;
  const destination = encodeURI(`${addressAndCity}, CA`);
  const directionUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  const phoneUrl = `tel:+1${phone}`;

  // save menuLeft as a state so that it is accessible by the render update on resize
  const [menuX, setMenuX] = useState(0);

  const placeStoreMenu = function() {
    const windowWidth = window.innerWidth;
    const icon = document.getElementById('utility-store__icon');

    if (windowWidth >= 800 && icon) {
      setMenuX(Math.max(icon.getBoundingClientRect().left, 0));
    } else {
      setMenuX(0);
    }
  };

  const handleToggle = (e) => {
    setActive(false);
  };

  const handleSetStore = (e) => {
    setStore(storeDrupalId(store));
  };

  useEffect(() => {
    placeStoreMenu();
    // console.log('placeStoreMenu');

    if (!eventsBound) {
      window.addEventListener('resize', placeStoreMenu);
      setEventsBound(true);
    }

    return function cleanup() {
      window.removeEventListener('resize', placeStoreMenu);
      setEventsBound(false);
    };
  }, [storeId]);

  return (
    <div
      role="button"
      tabIndex="0"
      className={styles.utilityStoreDropdownOverlay}
      onClick={handleToggle}
      onKeyPress={redirectKeyPressHandler(handleToggle)}
      style={{
        display: isActive ? 'block' : 'none',
      }}
    >
      <div
        className={styles.utilityStoreDropdownContent}
        onClick={(e) => e.stopPropagation()}
        style={{
          left: `${menuX}px`,
        }}
      >
        <header className={styles.utilityStoreDropdownHeader}>
          {isSuggestNearest && <span className={styles.utilityStoreDropdownSuggestClosest}>Closest store</span>}
          {!isSuggestNearest && <span className={styles.utilityStoreDropdownHeaderTitle}>{name}</span>}
          <span
            role="button"
            tabIndex="0"
            onClick={handleToggle}
            onKeyPress={redirectKeyPressHandler(handleToggle)}
            className={styles.utilityStoreDropdownHeaderClose}
          />
        </header>
        {isSuggestNearest && <div className={styles.utilityStoreDropdownHeaderTitle}>{name}</div>}
        <ul className={styles.utilityStoreDropdownDetails}>
          <li className={styles.utilityStoreDropdownDetailsTime}>{storeOpenHours(store)}</li>
          <li className={styles.utilityStoreDropdownDetailsAddress}>
            <span>{addressAndCity}</span>
            &nbsp;
            <a title="Directions" href={directionUrl} target="_blank" rel="noopener noreferrer">
              Directions
            </a>
          </li>
          <li className={styles.utilityStoreDropdownDetailsPhone}>
            <a href={phoneUrl}>{phone}</a>
          </li>
        </ul>
        <div className={styles.utilityStoreDropdownActions}>
          {storeId ? (
            <Link className={styles.utilityStoreDropdownActionsChange} to="/find-store">
              Change my store
            </Link>
          ) : (
            <span
              role="button"
              tabIndex="0"
              onClick={handleSetStore}
              onKeyPress={redirectKeyPressHandler(handleSetStore)}
              className={styles.utilityStoreDropdownActionsSave}
            >
              Save this store
            </span>
          )}
        </div>
        <Link className={styles.utilityStoreDropdownAllStores} to="/find-store">
          View all stores
        </Link>
      </div>
    </div>
  );
};

UtilityStoreDropdown.defaultProps = {
  store: null,
  isActive: false,
};

UtilityStoreDropdown.propTypes = {
  store: PropTypes.object,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
};

export default UtilityStoreDropdown;
