import ActionTypes from './action-types';
export const ClassHistoryItemsLoaded = (itemsPage) => ({
  type: ActionTypes.CLASS_HISTORY_ITEMS_LOADED,
  payload: itemsPage,
});

export const ClassHistoryItemsClear = () => ({
  type: ActionTypes.CLASS_HISTORY_ITEMS_CLEAR,
});

export const ClassHistoryItemUpdate = (item) => ({
  type: ActionTypes.CLASS_HISTORY_ITEM_UPDATE,
  payload: item,
})

export const GroceryHistoryItemsLoaded = (itemsPage) => ({
  type: ActionTypes.GROCERY_HISTORY_ITEMS_LOADED,
  payload: itemsPage,
});

export const GroceryHistoryItemsClear = () => ({
  type: ActionTypes.GROCERY_HISTORY_ITEMS_CLEAR,
});
