import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconRewards = (props) => {
  return (
    <SvgIcon {...props}>
      <g fill="none" stroke="#404041">
        <rect width="23.102338264338776" height="14.85150316993207" x="0.5" y="0.5" rx="2" id="svg_2"/>
        <path d="M12.449684467229233,5.7087521950989535 s1.2194734269533112,-1.4026419660491403 2.491752198510825,-1.0156778001214657 c1.273103855066955,0.38613908241823397 1.9372960801666945,1.736800787372612 1.3861402958603273,2.8671651953063306 c-0.5511557843063682,1.1303644079337187 -3.605614936255731,4.087463705769082 -3.8778924943711512,4.087463705769082 c-0.27227755811542154,0 -3.594888850633002,-2.8927427840989917 -3.995054352711727,-4.087463705769082 c-0.40016550207872537,-1.1955460051795317 0.2557758879266081,-2.481026112888096 1.5709590019750368,-2.8671651953063306 c1.3143580305389884,-0.38696416592767474 2.4240953507366902,1.0156778001214657 2.4240953507366902,1.0156778001214657 z" id="svg_3"/>
      </g>
    </SvgIcon>
  );
}

export default IconRewards;
