// Libs
import React, { useContext, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { CloseModal } from '../../store/modal/action-creators';
// Deps
import Navbar from './navbar';
import Footer from './footer';
import Modal from './modal';
// Assets
import styles from './layout.module.scss';
import { useStateValue } from '../../store/state';
import { uiBodyScrollable } from '../../store/ui/action-creators';
import { siteOriginIs, siteTypes } from '../../libs/api';
import pageAliases from '../../data/pageAliases'
import CookieBanner from "../../components/common/cookie-banner/cookie-banner";
// Services.
import crmClient from '../../services/crmClientService';
import navigate from '../../libs/navigate';

import { pushEvent } from '../../libs/dataLayer';
import getWindow from '../../libs/getWindow';

(function() {
  const matchMedia = getWindow('matchMedia');
  let onbeforeprint = getWindow('onbeforeprint');
  let onafterprint = getWindow('onafterprint');

  const beforePrint = function() {
   pushEvent('interaction', 'Printing event triggered', 'Print view started');
  };
  const afterPrint = function() {
    pushEvent('interaction', 'Printing event triggered', 'Print view completed');
  };

  if (matchMedia) {
    const mediaQueryList = matchMedia('print');
    mediaQueryList.addListener(function(mql) {
        if (mql.matches) {
            beforePrint();
        } else {
            afterPrint();
        }
    });
  }

  onbeforeprint = beforePrint;
  onafterprint = afterPrint;
}());

const Layout = ({ children, containered }) => {
  // remove modals from other pages.
  const [, dispatch] = useStateValue();
  const notify = typeof window !== 'undefined' ? window.notify : '';

  crmClient.useWebHeaders();

  useEffect(() => {
    dispatch(uiBodyScrollable());
    dispatch(CloseModal());
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event('notifychange'));
  }, [notify]);

  // If the current site is an app site, kill main site layouts. Must check for bool, as -1 can mean this is SSR.
  if (siteOriginIs(siteTypes.app) === true && getWindow('location.pathname').indexOf('/policies') < 0 && getWindow('location.pathname').indexOf('/flyers') < 0) {
    navigate(pageAliases.appRewards);
    return null;
  }

  return (
    <>
      <div className={styles.layoutWrapper}>
        <Navbar/>
        <div className={containered ? styles.container : ''}>
          <main className={styles.mainContainer}>{children}</main>
          <CookieBanner />
        </div>
        <Footer/>
      </div>
      <Modal/>
    </>
  );
};

Layout.defaultProps = {
  children: null,
  containered: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  containered: PropTypes.bool,
};

export default Layout;
