import { useStateValue } from '../store/state';
import crmService from '../services/crmClientService';

/**
 * Route newsletter subscription to guest/customer CRM endpoint call.
 *
 * Handle the state checks that determine when guest / customer CRM method
 * should be called and returns callable function routed to appropriate CRM
 * subscription method.
 *
 * @returns {function} - Callable function to subscribe to newsletter.
 */
export function useNewsletterSubscribeRouter() {
  const [state] = useStateValue();
  const { CRMCustomerID, EmailAddress } = state.customer;
  const { access_token } = state.user;

  return (email) => {
    const subscription = {
      EmailAddress: email,
    };

    if (email === EmailAddress) {
      return crmService.customerNewsletterSubscribe(CRMCustomerID, subscription, access_token);
    } else {
      return crmService.guestNewsletterSubscribe(email);
    }
  };
}
