import { useState, useEffect } from 'react';

export function useUserGeolocation(initialGeolocation) {
  const [geolocation, setGeolocation] = useState(initialGeolocation);

  const options = {
    enableHighAccuracy: false,
    timeout: 15000,
    maximumAge: 0,
  };

  const handleUpdate = ({ coords = {}, timestamp }) => {
    const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords;

    setGeolocation({
      accuracy,
      altitude,
      altitudeAccuracy,
      heading,
      latitude,
      longitude,
      speed,
      timestamp,
      error: null,
    });
  };

  const handleError = (error) => {
    setGeolocation({
      ...initialGeolocation,
      error,
    });
  };

  useEffect(() => {
    // Run geolocation if not known and supported by browser.
    if (
      initialGeolocation.timestamp === null &&
      initialGeolocation.error === null &&
      navigator.geolocation
    ) {
      navigator.geolocation.getCurrentPosition(handleUpdate, handleError, options);
    }
  }, []);

  return geolocation;
}
