/**
 * Add a notificiation message displayed with sitewide <Message> component.
 *
 * @param {string} message, status - Message to add.
 * @param {string} type - Message type.
 * @returns {boolean} - Flag indicating if message was added or not.
 */
// import {useContext} from "react";
// import {AppNotify} from "../components/layout/notifyContext";

export function addNotification(message, type = 'success') {
  // Input message must be an string, skip otherwise.
  // const [notify, setNotify] = useContext(AppNotify);
  if (typeof message !== 'string') {
    return false;
  }

  // Skip add notification message on nodejs executions like gatsby build.
  if (typeof window === 'undefined') {
    return false;
  }

  if (typeof window.notify === 'undefined') {
    // No other messages so init an array with our message.
    window.notify = [{type:type, message: message}];
    window.dispatchEvent(new Event('notifychange'));
    // setNotify([{type:type, message: message}])
    return true;
  }

  if (Array.isArray(window.notify)) {
    const similarMessages = window.notify.filter(entry => entry.message === message);
    if (similarMessages.length === 0) {
      // There are other messages so push it to the array.
      window.notify.push({type:type, message: message});
      window.dispatchEvent(new Event('notifychange'));
      // setNotify(...notify, [{type:type, message: message}])
      return true;
    }
  }
}

/**
 * Clear all notifications from window notify.
 *
 * @returns {boolean} - Flag indicating if notifications was cleared.
 */
export function clearNotifications() {
  if (typeof window === 'undefined') {
    return false;
  }

  window.notify = [];
  return true;
}

/**
 * Get all notifications.
 */
export function getNotifications() {
  if (typeof window === 'undefined') {
    return [];
  }
  return Array.isArray(window.notify) ? window.notify : [];
}
