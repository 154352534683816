// Libs
import React, { useState } from 'react';
import FormInput from '../common/form-input';
import formValidators from '../../libs/form/form-validator';
// Components.
import ErrorMessages from '../message/error-messages';
import SuccessMessages from '../message/success-messages';
// Hooks.
import { useNewsletterSubscribeRouter } from '../../hooks/useNewsletterSubscribeRouter';
// Assets.
import styles from './footer-subscribe.module.scss';
import ButtonLoader from '../common/loaders/button-loader';

const FooterSubscribe = () => {
  // @TODO add API call to add email to list
  const [email, setEmail] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const newsletterSubscribe = useNewsletterSubscribeRouter();
  const [submission, setSubmission] = useState(false);

  const handleSubmit = (e) => {
    setSubmission(true);
    e.preventDefault();
    // Find the email input from form elements.
    const nativeEvent = e.nativeEvent;
    const formElements = Array.from(nativeEvent.target.elements);
    const formInputs = formElements.filter((element) => {
      return ['email'].includes(element.type);
    });

    const subscribeEmail = formInputs[0].value;
    newsletterSubscribe(subscribeEmail)
      .then((data) => {
        setSubmission(false);
        // Clear the email input.
        setEmail('');
        // Clear errors.
        setErrorMessages([]);
        // Display success subscription message.
        setSuccessMessages(['Your newsletter subscription was completed.']);
      })
      .catch((error) => {
        setSubmission(false);
        setErrorMessages([error.message]);
      });
  };

  return (
    <form className={styles.footerSubscribe} onSubmit={handleSubmit}>
      <ErrorMessages messages={errorMessages}/>
      <SuccessMessages messages={successMessages}/>
      <div className={styles.footerSubscribeTitle}>Sign up For news, exclusive offers &amp; more</div>
      <div className={styles.footerSubscribeInputButton}>
        <FormInput
          className={styles.footerSubscribeInput}
          required={true}
          type="email"
          placeholder="Your email"
          onChange={(e) => formValidators.email(e.target.value, email, setEmail)}
        />
        <button className={styles.footerSubscribeButton} aria-label="Submit" disabled={submission}>
          Submit
          {submission && <ButtonLoader/>}
        </button>
      </div>
    </form>
  );
};

export default FooterSubscribe;
