import { getDistance } from 'geolib';
import { defaults, has, isObject, orderBy, mapValues, get, groupBy } from 'lodash';
import { divmod } from '../math';
import { transformTo12 } from '../timeTransform';

export function calculateStoresDistance(stores, sourceLocation) {
  const storesWithDistance = stores.map((item) => {
    let distanceKms;
    const targetLocation = {
      latitude: item.lat,
      longitude: item.lon,
    };

    if (item.lat !== undefined && item.lon !== undefined) {
      // Distance is represented as meters so divide by 1000 to convert to kms.
      distanceKms = getDistance(sourceLocation, targetLocation) / 1000;
    }

    // Add calculated distance to the store object.
    return defaults(
      {
        distance: distanceKms,
      },
      item,
    );
  });

  return storesWithDistance;
}

export function sortStoresByDistance(storesWithDistance) {
  return orderBy(storesWithDistance, ['distance'], ['asc']);
}

/**
 * Find nearest store to user location.
 *
 * @param {Array} stores - All stores collection.
 * @param {Object} userLocation - Location object with latitude and longitude properties.
 * @returns - The nearest store object or empty object.
 */
export function getNearestStore(stores, userLocation) {
  const storesWithDistance = calculateStoresDistance(stores, userLocation);
  const storesByDistance = sortStoresByDistance(storesWithDistance);
  return storesByDistance[0] || {};
}

export function getInactiveStoresPoints(stores, activeStoreIdx) {
  // console.log(activeStoreIdx)
  const inactiveStores = activeStoreIdx < 0 ? stores : stores.filter((store, idx) => idx !== activeStoreIdx);
  return inactiveStores.map((store, index) => {
    return {
      nid: store.nid,
      lat: store.lat,
      lng: store.lon,
    };
  });
}

/**
 * Provides the store property name used as primary ID.
 *
 * @returns {string} - Property name.
 */
export function storeDrupalIdPropName() {
  return 'nid';
}

/**
 * Get store primary id propety.
 *
 * Helper to invoke in all places where store primary ID is used.
 *
 * @param {Object} store - An store object.
 * @returns {Number} - The store ID.
 */
export function storeDrupalId(store) {
  if (isObject(store) && has(store, storeDrupalIdPropName())) {
    return Number(store[storeDrupalIdPropName()]);
  }
}

/**
 * Provides CRM store property name.
 *
 * @returns {string} - Property name.
 */
export function storeCrmIdPropName() {
  return 'crmId';
}

/**
 * Get store CRM id propety.
 *
 * Helper to invoke in all places where store CRM ID is used.
 *
 * @param {Object} store - An store object.
 * @returns {Number} - The store ID.
 */
export function storeCrmId(store) {
  if (isObject(store) && has(store, storeCrmIdPropName())) {
    return Number(store[storeCrmIdPropName()]);
  }
}

/**
 * Find store by primary store ID within stores collection.
 *
 * @param {Array} stores - Stores objects collection.
 * @param {Number} storeId - Store Drupal ID to lookup.
 * @returns {Object} - The found store object.
 */
export function getStore(stores, storeId) {
  const store = stores.find((store) => storeDrupalId(store) === storeId);
  return store;
}

/**
 * Find store with orders pick up possibility by primary store ID within stores collection.
 *
 * @param {Array} stores - Stores objects collection.
 * @param {Number} storeId - Store Drupal ID to lookup.
 * @returns {Object} - The found store object.
 */
export function getPickUpStore(stores, storeId) {
  let pickUpStore;
  const store = stores.find((store) => storeDrupalId(store) === storeId);
  const validValue = store.field_store_pick_up_validation;

  if (validValue && validValue === true) {
    pickUpStore = store;
  }

  return pickUpStore;
}

/**
 * Get all pick up stores.
 */
export function getPickUpStores(allStores) {
  const allPickUpStores = allStores.filter(store => store.pickUpValid);
  let storesData;
  let storeData;

  storesData = allPickUpStores.map((node) => {
    storeData = {
      storeId: node.crmId,
      title: node.title,
      pickUpHours: node.pickUpHours,
      pickUpDate: node.pickUpDate,
    };

    return storeData;
  });

  return storesData;
}

/**
 * Get pick up hours range by storeId and special day(string).
 */
export function getPickUpHourRange(allStores, storeId, day) {
  let pickUpStoreHours;
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayKey = days.indexOf(day);
  const allPickUpStores = getPickUpStores(allStores);
  const pickUpStore = allPickUpStores.find(store => store.storeId.toString() === storeId.toString());

  pickUpStoreHours = pickUpStore.pickUpHours.filter(dow => dow.day === dayKey);

  return pickUpStoreHours;
}

/**
 * Get pick up hours range by storeId and special day(string).
 */
export function getPickUpDatesRange(allStores, storeId) {
  let pickUpStores;
  const allPickUpStores = getPickUpStores(allStores);
  const pickUpStore = allPickUpStores.find(store => store.storeId.toString() === storeId.toString());
  pickUpStores = mapValues(get(pickUpStore, 'pickUpDate'), (value) => {
    return value;
  });

  return pickUpStores;
}

/**
 * Returns an array of dates between the two dates.
 */
export function getPickUpDatesBetween(startDate, endDate) {
  const dates = [];

  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );

  while (currentDate <= endDate) {
    dates.push(currentDate);

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
    );
  }

  return dates;
}

/**
 * Boost my store position to the top within store list.
 *
 * @param {Array} stores - Stores objects collection.
 * @param {Number} myStoreId - My store Drupal ID.
 * @returns - The stores collection with boosted my store.
 */
export function boostMyStore(stores, myStoreId) {
  // Skip relevance when undefined / null store ID.
  if (!myStoreId) {
    return stores;
  }

  // Remove my store from stores list.
  const storesByRelevance = stores.filter((store) => {
    return storeDrupalId(store) !== myStoreId;
  });

  // Find my store object and return original list if not found.
  const myStore = getStore(stores, myStoreId);
  if (!myStore) {
    return stores;
  }

  // Add my store at the start of the list.
  storesByRelevance.unshift(myStore);

  return storesByRelevance;
}
