// State
import locale from '../../locale/locale';

const exists = (value, fieldState, dispatch) => {
  const formLocalState = { ...fieldState, value: value, error: false };
  formLocalState.error = !value;
  dispatch(formLocalState);
};

const password = (value, fieldState, dispatch) => {
  const formLocalState = { ...fieldState, value: value, error: false };
  if (String(value).length < 8 ) {
    formLocalState.error = true;
  } else {
    const re = /^[a-zA-Z0-9!@#$%^&*)(+=._-]*([0-9]+[a-zA-Z]|[a-zA-Z]+[0-9])[a-zA-Z0-9!@#$%^&*)(+=._-]*$/g;
    formLocalState.error = !re.test(String(value));
  }
  dispatch(formLocalState);
};

const email = (value, fieldState, dispatch) => {
  const formLocalState = { ...fieldState, value: value, error: false };
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  formLocalState.error = !re.test(String(value).toLowerCase());
  formLocalState.errorMessage = locale.form.emailError;
  dispatch(formLocalState);
};

const phone = (value, fieldState, dispatch) => {
  const formLocalState = { ...fieldState, value: value, error: false };
  const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  formLocalState.error = !re.test(String(value).toLowerCase());
  dispatch(formLocalState);
};

const postalCode = (value, fieldState, dispatch) => {
  const formLocalState = { ...fieldState, value: value, error: false };
  const re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  formLocalState.error = !re.test(String(value).toLowerCase());
  dispatch(formLocalState);
};

const tyrNumber = (value, fieldState, dispatch) => {
  const formLocalState = { ...fieldState, value: value, error: false };
  const re = /^[0-9]{11}$/;
  if (value.length > 0) {
    formLocalState.error = !re.test(String(value).toLowerCase());
  }
  dispatch(formLocalState);

  // console.log('tyrNumber', !formLocalState.error);

  return !formLocalState.error;
};

const tyrNumberRaw = (value) => {
  let result = false;
  const re = /^[0-9]{11}$/;
  if (value.length > 0) {
    result = !re.test(String(value).toLowerCase());
  }

  return result;
};

export default {
  exists,
  email,
  password,
  phone,
  postalCode,
  tyrNumber,
  tyrNumberRaw,
};
