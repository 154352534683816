import { has, isEmpty } from 'lodash';
import { useState } from 'react';
import { useStateValue } from '../store/state';

export const useFlyerLink = () => {
  const [state] = useStateValue();
  const [myStore] = useState(null);
  return has(state, 'customer.CRMCustomerID') &&
    !isEmpty(state.customer.CRMCustomerID) &&
    myStore &&
    has(myStore, 'field_store_flyer_link.uri')
    ? myStore.field_store_flyer_link.uri
    : '/flyers';
};
