import { UserLogoutComplete, UserSetCookie } from '../../store/user/action-creators';
import { CustomerLogout } from '../../store/customer/action-creators';
import { ResetCheckoutCustomer } from '../../store/checkout/action-creators';
import { ClassHistoryItemsClear, GroceryHistoryItemsClear } from '../myAccount/action-creators';

export function completeLogout(dispatchCallback) {
  dispatchCallback(UserLogoutComplete());
  dispatchCallback(CustomerLogout());
  dispatchCallback(ResetCheckoutCustomer());
  dispatchCallback(ClassHistoryItemsClear());
  dispatchCallback(GroceryHistoryItemsClear());
}

export function setUserCookie(dispatchCallback, cookie) {
  dispatchCallback(UserSetCookie(cookie));
}
