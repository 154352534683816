// Libs.
import React from 'react';
// Assets.
import styles from './footer-menu.module.scss';
import SiteLink from '../common/site-link';

export default ({ items }) => {
  return (
    <ul>
      {items.map((item, i) => {
        return (
          <li className={styles.footerMenuItem} key={i}>
            <SiteLink href={item.node.link.uri}>{item.node.title}</SiteLink>
          </li>
        );
      })}
    </ul>
  );
};
