import crmService from '../services/crmClientService';
import { useStateValue } from '../store/state';
import { CustomerData } from '../store/customer/action-creators';

const useCustomer = () => {
  const [state, dispatch] = useStateValue();

  const getCustomer = async () => {
    if (state.user.CRMCustomerID) {
      const customer = await crmService.customerGet(
        state.user.CRMCustomerID,
        state.user.access_token
      );
      dispatch(CustomerData(customer));
      const points = await crmService.tyrCardPointsGet(
        state.user.CRMCustomerID,
        state.user.access_token
      );
      dispatch(CustomerData(points));

      // dispatch(CardBalanceLoaded(2000));
      // console.log('customerGet', customer, points);
    }
  };

  const getCustomerPoints = async () => {
    if (state.user.CRMCustomerID) {
      const points = await crmService.tyrCardPointsGet(
        state.user.CRMCustomerID,
        state.user.access_token
      );
      dispatch(CustomerData(points));

      // dispatch(CardBalanceLoaded(2000));
      // console.log('customerGet', customer, points);
    }
  };

  return {
    getCustomer,
    getCustomerPoints,
  };
};

export default useCustomer;
