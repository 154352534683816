import { useEffect } from 'react';
import { partial, isFunction } from 'lodash';
// Services.
import crmClient from '../services/crmClientService';
// Custom Libs.
import { addNotification } from '../libs/notify';
import { delayMins } from '../libs/delay';
import navigate from '../libs/navigate';
import getWindow from '../libs/getWindow';
// State.
import { useStateValue } from '../store/state';
import { completeLogout } from '../store/user/actions';
// Hooks.
import useInterval from './useInterval';
// Assets.
import appAliases from '../data/appAliases.json';
import pageAliases from '../data/pageAliases.json';
import { utilityMyAccount as locale } from '../locale/locale.json';

export function tokenExpiredLogout(isApp, dispatch) {
  const pathname = getWindow('location.pathname');
  const currentLocation = encodeURIComponent(pathname);
  // Pass destination path to allow return to last visited page after
  // user completes new login.
  if (isApp) {
    navigate(`${appAliases.appAccountProfile}?destination=${currentLocation}`);
  } else {
    navigate(`${pageAliases.login}?destination=${currentLocation}`);
  } // Notify auto logout reason.

  addNotification(locale.messages.sessionExpire);
  completeLogout(dispatch);
}

export function useCheckIfValidToken(logoutCallback) {
  const [state] = useStateValue();
  const { access_token, CRMCustomerID } = state.user;
  const pathname = getWindow('location.pathname');
  const doCheck = async () => {
    if (access_token !== '' && pathname !== null) {
      crmClient.userSessionValidate(CRMCustomerID, access_token).catch((error) => {
        // Token is invalid or expired, close session.
        if (error.requestStatus === 401) {
          isFunction(logoutCallback) && logoutCallback();
        }
      });
    }
  };

  return doCheck;
}

const useAuthenticationExpire = (isApp = false) => {
  const [state, dispatch] = useStateValue();
  const { access_token } = state.user;
  const handleLogout = partial(tokenExpiredLogout, isApp, dispatch);
  const handleTokenCheck = useCheckIfValidToken(handleLogout);
  let intervalDelay = delayMins(5);

  // Increase token check frequency within checkout pages per Vlad request at
  // task LON-1716.
  const pathname = getWindow('location.pathname') || '';
  if (pathname.indexOf('/checkout') !== -1) {
    intervalDelay = delayMins(2);
  }

  // Fire on load if the user is currently logged in. Will only fire if the user token changes.
  useEffect(() => {
    setTimeout(() => {
      if (access_token !== '') {
        handleTokenCheck();
      }
    }, 200)

  }, [access_token]);

  // Interval to monitor when session token expires.
  useInterval(handleTokenCheck, intervalDelay);
};

export default useAuthenticationExpire;
