import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SelectedIconFresh = (props) => {
  const styles = {
    colorPrimary: "#414141"
  };
  return (
    <SvgIcon {...props} viewBox="-1 -0.6 23 24" classes={styles}>
      <g fill="none" stroke="#404041">
        <path stroke="green" d="M14.2309671,4.0497837 C15.3993384,3.04258201 16.5707505,0.947274437 15.6945532,0.242886647 C14.818356,-0.461501143 12.2263475,0.491768179 11.0579762,1.49896987 C9.88960493,2.50617155 9.65275108,3.89368897 10.5289483,4.59807676 C11.4051456,5.30246455 13.0625958,5.05698539 14.2309671,4.0497837 Z 9.77777778,7 C10.0740741,6.16666667 10.0740741,5.33333333 9.77777778,4.5 C9.48148148,3.66666667 8.88888889,2.83333333 8,2" id="Oval"/>
        <path stroke="#404041" d="M9.77777778,7 C10.0740741,6.16666667 10.0740741,5.33333333 9.77777778,4.5 C9.48148148,3.66666667 8.88888889,2.83333333 8,2" id="Line"/>
        <path stroke="#e52527" d="M11.7590078,21.9924362 C16.7757393,21.8382973 20,18.1979486 20,12.8092054 C20,9.59168083 18.1247492,7.68113392 16.5921021,6.59362693 C15.557856,5.85976602 12.9425049,6.07507902 12.9425049,6.07507902 C12.6455656,6.07507902 10.1997051,7.14291688 9.90325928,7.16423334 C7.81965911,7.31405828 7.09377342,5.87577266 5.57250977,6.07507902 C5.19049506,6.12512817 5.90727154,5.88345744 5.11413574,6.07507902 C4.79199442,6.15290835 5.29688757,5.8968076 4.70947266,6.07507902 C4.38046458,6.17492792 3.77380074,6.31106027 3.08807373,6.59362693 C2.71608183,6.74691315 2.71608183,6.74691315 1.93158318,7.16423334 C1.42335958,7.43458685 0.650852088,9.01958112 0.54486443,9.38854167 C0.261218534,10.3759599 0,12.4039386 0,12.8092054 C0,17.5345549 3.80642682,21.2024322 7.96214099,21.9924362 C8.33631935,22.0635677 9.29163099,21.6157583 10,21.372243 C10.1652963,21.4704594 9.74365402,21.2552508 10.1597911,21.372243 C10.3225813,21.4180096 10.0153174,21.1172183 10.6,21.372243 C10.8038884,21.4611743 11.7590078,21.9924362 11.7590078,21.9924362 Z" id="Oval"/>
      </g>
    </SvgIcon>
  );
};

export default SelectedIconFresh;
