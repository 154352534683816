// Libs.
import React, { useEffect, useState } from 'react';
import { has, map, isEmpty } from 'lodash';
// Deps.
import Cart from '../layout/cart';
import Logo from '../layout/logo';
import SubMenu from './sub-menu';
import SiteLink from '../common/site-link';
// Assets.
import styles from './primary-menu.module.scss';
// Hooks.
import { useMenuActive } from '../../hooks/useMenuActive';
import useMenuItemsStaticQuery from '../../hooks/useMenuItemsStaticQuery';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import ShopOnline from '../layout/shop-online';

export default () => {
  const menuActive = useMenuActive();
  const menuItems = useMenuItemsStaticQuery();
  const [sizes, setSizes] = useState([]);
  const [line, moveLine] = useState([]);
  // Count menu sub-items for a parent item.
  useEffect(() => {
    const links = document.querySelectorAll('[class*="primary-menu-module--primary-menu"]>li');
    setSizes(
      map(links, function(link, i) {
        return [link.offsetLeft + link.offsetWidth / 2, link.offsetWidth];
      })
    );
    // setMenus()
  }, [line]);
  useEffect(() => {
    if (isEmpty(menuActive.activeParent)) {
      moveLine([line[0], 0]);
    }
  }, [menuActive.activeParent]);
  const hasChilds = (items, parentId) => {
    const childs = items.filter((item) => {
      return item.node.fields.parent_menu_id === `menu_link_content:${parentId}`;
    });

    return !!childs.length;
  };
  // Display primary menu root items.
  const renderPrimaryMenuItems = () => {
    // Filter only parent items, currently GraphQL implementation is not able
    // to filter by null or undefined values:
    // https://github.com/gatsbyjs/gatsby/issues/6800
    const primaryItems = menuItems.filter((item) => item.node.fields.parent_menu_id === null);

    // console.log('menuActive', { menuActive, primaryItems });
    return (
      <>
        {/* <li data-childs={false} className={styles.primaryMenuBarGgIconMobile}>
          <ShopOnline className={styles.primaryMenuBarGgIcon} />
        </li> */}
        {primaryItems.map((item, i) => {
          const activeMenu =
            !isEmpty(sizes) &&
            has(item, 'node.drupal_id') &&
            menuActive.activeParent === item.node.drupal_id
              ? i
              : -1;
          const childsFlag = hasChilds(menuItems, item.node.drupal_id);
          return (
            <li
            onMouseLeave={() => {
              !isEmpty(sizes) && !isEmpty(sizes[activeMenu])
                ? moveLine(sizes[activeMenu])
                : moveLine([line[0], 0]);
            }}
            onMouseEnter={() => {
              !isEmpty(sizes) && moveLine(sizes[i]);
            }}
              key={item.node.drupal_id}
              data-childs={childsFlag}
              className={
                has(item, 'node.drupal_id') && menuActive.activeParent === item.node.drupal_id
                  ? styles.primaryMenuPrimaryActive
                  : null
              }
            >
              <SiteLink
                data-id={item.node.drupal_id}
                data-childs={childsFlag}
                href={item.node.link.uri}
                onClick={menuActive.handleActiveParent}
              >
                {item.node.title}
              </SiteLink>
            </li>
          );
        })}
        {/* <li data-childs="false" className={styles.primaryMenuBarGgIconDesktop}>
          <ShopOnline className={`${styles.primaryMenuBarGgIcon}`} />
        </li> */}
      </>
    );
  };

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        onClick={menuActive.closeSubmenu}
        onKeyPress={redirectKeyPressHandler(menuActive.closeSubmenu)}
        className={styles.primaryMenuBar}
      >
        <Logo />
        <ul className={styles.primaryMenu}>
          {renderPrimaryMenuItems()}
          {!isEmpty(sizes) && !isEmpty(line) && (
            <span
              className={styles.slideLine}
              style={{ width: `${line[1]}px`, display: 'block', left: `${line[0]}px` }}
            /> 
          )}
        </ul>
        <Cart className={styles.primaryMenuBarCartIcon} />
      </div>
      <SubMenu menuItems={menuItems} />
    </>
  );
};