import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {makeStyles} from "@material-ui/core";

const IconAccount = (props) => {
  return (
    <SvgIcon {...props} fontSize="small" style={{marginBottom: 12}}>
      <g fill="none" stroke="#414141" transform="translate(0 1.1) scale(1.2 1.2)">
        <ellipse cx="9.2" cy="4.375" rx="4.2" ry="4.375"/>
        <path d="M18.6 18.292C18.6 14.265 14.436 11 9.3 11 4.164 11 0 14.265 0 18.292"/>
      </g>
    </SvgIcon>
  );
}

export default IconAccount;
