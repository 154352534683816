import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';

const useMenuItemsStaticQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMenuLinkContentMain(
        sort: { order: ASC, fields: [weight] }
        filter: { enabled: { eq: true } }
      ) {
        edges {
          node {
            drupal_id
            menu_name
            title
            weight
            fields {
              parent_menu_id
            }
            link {
              uri
            }
            field_teaser_display
            field_teaser_heading
            field_teaser_body {
              processed
            }
            field_teaser_link {
              uri
              title
            }
            relationships {
              field_teaser_image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxHeight: 810) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const saveNode = () => {
    return data.allMenuLinkContentMain.edges || [];
  };

  useMemo(() => saveNode(), data.allMenuLinkContentMain);

  return saveNode();
};

export default useMenuItemsStaticQuery;
