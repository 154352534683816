import { useState, useEffect } from 'react';
import { useStateValue } from '../store/state';
import { uiBodyScrollable, uiBodyUnscrollable } from '../store/ui/action-creators';
import { isMobileOnly } from 'react-device-detect';
import { pushEvent } from '../libs/dataLayer';

export const menuStore = {
  state: {},
  setState(value) {
    this.state = value;
    this.setters.forEach((setter) => setter(this.state));
  },
  setters: [],
};

// Custom hook to handle menu/sub-menu activation.
export const useMenuActive = () => {
  const [activeParent, setActiveParent] = useState('');
  const [, dispatch] = useStateValue();
  if (!menuStore.setters.includes(setActiveParent)) {
    menuStore.setters.push(setActiveParent);
  }

  // Clean setters on component unmount.
  useEffect(
    () => () => {
      menuStore.setters = menuStore.setters.filter((setter) => {
        return setter !== setActiveParent;
      });
    },
    [],
  );

  return {
    activeParent: activeParent,
    setActiveParent: menuStore.setState,
    // Handle submenu overlay close.
    closeSubmenu: function(e) {
      pushEvent('Interaction', 'Menu', `Sub Menu closed`);
      menuStore.setState('');
      if (isMobileOnly) {
        dispatch(uiBodyUnscrollable());
      } else {
        dispatch(uiBodyScrollable());
      }
    },
    // Prevent click propagation to overlay container so sub-menu only close on
    // clicks outside overlay content.
    preventClose: function(e) {
      e.stopPropagation();
    },
    // Activate selected parent menu item.
    handleActiveParent: function(e) {
      const { id, childs } = e.target.dataset;

      // console.log('handleActiveParent', e.target.dataset);

      // If root item has child's activate item to trigger open of sub-items
      // drop-down, otherwise should behave as normal link.
      if (childs === 'true') {
        e.stopPropagation();
        e.preventDefault();
        if (id === activeParent) {
          menuStore.setState('');
          pushEvent('Interaction', 'Menu', `Sub Menu closed ${e.target.text}`);
        } else {
          menuStore.setState(id);
          pushEvent('Interaction', 'Menu', `Sub Menu opened ${e.target.text}`);
        }
        if (!isMobileOnly && menuStore.state) {
          dispatch(uiBodyUnscrollable());
        } else {
          dispatch(uiBodyScrollable());
        }

      } else {
        menuStore.setState('');
      }
    },
  };
};