// Redirect event handler on keypress of given keys, used on non
// clickable elements for accesibility purposes.
export const redirectKeyPressHandler = (eventHandler) => (e) => {
  const key = e.key || e.which || e.charCode || e.code;
  // Based on keycode tool https://keycode.info/ the possibles 'Enter' pressed
  // key values are check here.
  if (key === 'Enter' || key === 13) {
    eventHandler(e);
  }
};
