import { useState } from 'react';
export const useToggle = () => {
  const [isActive, setActive] = useState(false);

  // Toggle state.
  const toggle = (forced) => {
    typeof forced === 'boolean'
      ? setActive(forced) // Either we're forcing a setting
      : setActive(!isActive); // Or we're inverting the current value
    // console.log('navToggle', { isActive, forced });
    return isActive;
  };

  return {
    isActive: isActive,
    toggle: toggle,
  };
};
