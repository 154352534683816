// Libs
import React from 'react';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import Img from 'gatsby-image';
import FooterAccessibilityIcon from './footer-accessibility-icon';
import { get } from 'lodash';

const FooterSocialIcons = ({ items }) => {
  const images = {
    'icons/instagram.svg': useImageStaticQuery('icons/instagram.svg'),
    'icons/facebook-alt.svg': useImageStaticQuery('icons/facebook-alt.svg'),
    'icons/linkedin.svg': useImageStaticQuery('icons/linkedin.svg'),
    'icons/twitter.svg': useImageStaticQuery('icons/twitter.svg'),
  };

  return (
    <ul>
      {items.map((item, i) => {
        const icon = images[item.icon];

        return (
          <li key={i}>
            <a href={item.link} target="__blank">
              {!icon.childImageSharp ? (
                <img src={icon.publicURL} alt="social icon"/>
              ) : (
                <Img fluid={icon.childImageSharp.fluid}/>
              )}
            </a>
          </li>
        );
      })}
      <li key="accessibility">
        <FooterAccessibilityIcon/>
      </li>
    </ul>
  );
};

export default FooterSocialIcons;
