// Libs
import React from 'react';
// Deps
import MyAccount from './utility-account';
import MyStore from './utility-store';
// Assets
import styles from './utility-menu.module.scss';
import locale from '../../locale/locale';
import { useFlyerLink } from '../../libs/flyer-link';

export default () => {
  const flyerLink = useFlyerLink();

  return (
    <div className={styles.utilityMenu}>
      <div className={styles.utilityMenuContainer}>
        <MyStore/>
        <div className={styles.utilityMenuColumn}>
          <div className={styles.utilityMenuFlyers}>
            <a title={locale.globalMenu.flyers} href={flyerLink}>
              {locale.globalMenu.flyers}
            </a>
          </div>
          <MyAccount/>
        </div>
      </div>
    </div>
  );
};
