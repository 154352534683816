import React, { useContext } from 'react';
import styles from './form-loader.module.scss';
import { AppContext } from '../../layout/AppLayout';
const ButtonLoader = () => {
  const app = useContext(AppContext);
  return (
    <div className={styles.ldsRingBtn} data-app={app}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonLoader;
