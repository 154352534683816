// Libs
import React, { useState, useEffect } from 'react';
import { isObject } from 'lodash';
// Components.
import UtilityStoreDropdown from './utility-store-dropdown';
import SiteLink from "../common/site-link";
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Hooks.
import { useDefaultStore } from '../../hooks/useDefaultStore';
import { useStoresStaticQuery } from '../../hooks/useStoresStaticQuery';
import { useUserGeolocation } from '../../hooks/useUserGeolocation';
// State.
import { useStateValue } from '../../store/state';
import { UserSetLocation, UserSuggestedStore } from '../../store/user/action-creators';
import { getStore, getNearestStore } from '../../libs/store/store-utils';
import { useStores } from '../../services/storesIndexService';

// Assets
import icon from '../../images/icons/icon-location.svg';
import styles from './utility-store.module.scss';
import navigate from '../../libs/navigate';

const MyStore = () => {
  const [state, dispatch] = useStateValue();
  const stores = useStoresStaticQuery();
  const storesIndex = useStores();
  const { storeId, nearestStoreId } = useDefaultStore();
  const userGeolocation = useUserGeolocation(state.user.location);
  const [myStore, setMyStore] = useState(null);
  const [, displayStoreDropdown] = useState(state.user.defaultStoreId);
  const [dropdownActive, setActive] = useState(false);
  const suggestNearest = isObject(myStore) && !state.user.defaultStoreId;

  // If we retrieved. the users suggested store, load it and display it. Once, only ever once.
  useEffect(() => {
    setTimeout(suggestStore, 100);
  }, [userGeolocation, state.user]);

  const suggestStore = () => {
    if (!state.user.location.longitude && !!userGeolocation.longitude && !state.user.defaultStoreId && !state.user.suggestedStore.once) {
      // Dispatch set location to store state.
      dispatch(UserSetLocation({ location: userGeolocation }));

      const userLocation = {
        latitude: userGeolocation.latitude,
        longitude: userGeolocation.longitude,
      };

      const nearestStore = getNearestStore(storesIndex.getAll(), userLocation);
      if (nearestStore) {
        setMyStore(nearestStore);
        displayStoreDropdown(true);
        setActive(true);

        // console.log('should show one-time store modal', nearestStore, userGeolocation, state.user);

        // Now we're done, don't display this again till localStorage is cleared.
        dispatch(UserSuggestedStore(true, nearestStore));
      }
    }
  };

  useEffect(() => {
    const defaultStoreId = storeId || nearestStoreId;
    setMyStore(getStore(stores, defaultStoreId));
  }, [storeId, nearestStoreId]);

  // Toggle dropdown activation.
  const toggleState = () => {
    setActive(!dropdownActive);
  };

  const handleClick = (e) => {
    if (window.innerWidth > 800) {
      toggleState();
    } else {
      navigate('/find-store');
    }
  };

  // console.log('utility store', {
  //   myStore,
  //   dropdownActive,
  //   setActive,
  //   suggestNearest,
  // });

  return (
    <div className={styles.utilityStore}>
      <div className={styles.utilityStoreNotice}>
        <img className={styles.utilityStoreIcon} src={icon} alt="Icon" id="utility-store__icon"/>
        {isObject(myStore) && !!state.user.defaultStoreId ?
          (
            <div
              role="button"
              tabIndex="0"
              className={styles.utilityStoreInfo}
              onClick={handleClick}
              onKeyPress={redirectKeyPressHandler(handleClick)}
            >
              <span className={styles.utilityStoreLabel}>My Store</span>
              <span className={styles.utilityStoreName}>{myStore.name}</span>
              {/*<span className={styles.utilityStoreTime}>{myStore.openTime}</span>*/}
            </div>
          ) : (
            <SiteLink
              className={styles.utilityStoreInfo}
              href="/find-store"
            >
              <span className={styles.utilityStoreLabel}>Find a store</span >
            </SiteLink>
          )}
      </div>
      {isObject(myStore) && (
        <UtilityStoreDropdown
          store={myStore}
          isActive={dropdownActive}
          setActive={setActive}
          suggestNearest={suggestNearest}/>
      )}
    </div>
  );
};

export default MyStore;
