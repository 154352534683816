// Libs.
import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, map, get } from 'lodash';
// Custom Libs.
import { clearNotifications, getNotifications } from '../../../libs/notify';
import { redirectKeyPressHandler } from '../../../libs/accessibility';
// Assets.
import styles from './message.module.scss';
import closeIcon from '../../../images/icons/icon-close-black.svg';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: '#74AA50',
    flexWrap: 'nowrap',
  },
  error: {
    backgroundColor: '#E52527',
    flexWrap: 'nowrap',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbar: {
    marginBottom: '80px',
    zIndex: 3000,
  },
}));

const SnackbarContentItem = ({ message, key, onClose, other }) => {
  const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
  };
  const Icon = variantIcon[message.type];
  const classes = useStyles1();
  const [status, setStatus] = useState(true);
  return (
    <>
      {status && (
        <SnackbarContent
          key={key}
          aria-describedby="client-snackbar"
          className={classes[message.type]}
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {message.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => {
                onClose(key);
                setStatus(false);
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
          {...other}
        />
      )}
    </>
  );
};

const SnackbarContentWrapper = (props) => {
  const { className, onClose, notify, ...other } = props;
  return (
    <div>
      {map(notify, (message, key) => {
        return <div key={key}><SnackbarContentItem message={message} onClose={onClose} other={other} /></div>;
      })}
    </div>
  );
};

const Message = ({ isApp = false }) => {
  const [notify, setNotify] = useState([]);
  const handleCloseClick = () => {
    setNotify([]);
    clearNotifications();
  };
  const handleClose = (key) => {
    const newArray = JSON.parse(JSON.stringify(notify));
    newArray.splice(key, 1);
    setNotify(newArray);
    clearNotifications();
  };
  const onHashChanges = () => {
    const notifications = getNotifications();
    if (notifications.length > 0) {
      setNotify(notifications);
    }
  };
  // const wNotify = get(window, 'notify', []);
  useEffect(() => {
    window.addEventListener('notifychange', onHashChanges);
    return () => window.removeEventListener('notifychange', onHashChanges);
  }, []);
  const classes = useStyles1();
  return (
    <div>
      {!isEmpty(notify) && isApp && (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            className={classes.snackbar}
            open={!!notify.length}
            onClose={handleClose}
          >
            <SnackbarContentWrapper notify={notify} onClose={handleClose} />
          </Snackbar>
        </div>
      )}
      {!isEmpty(notify) &&
        !isApp &&
        map(notify, (message, key) => (
          <div className={styles.messageType} data-type={message.type} key={key}>
            <div className={styles.messageContainer}>
              <img
                role="button"
                tabIndex="0"
                src={closeIcon}
                className={styles.messageClose}
                onClick={handleCloseClick}
                onKeyPress={redirectKeyPressHandler(handleCloseClick)}
                alt="close"
              />
              <div className={styles.messageText}>{message.message}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Message;
