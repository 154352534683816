// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { keys, difference } from 'lodash';

const SiteLink = ({ children, ...props }) => {
  let link;
  const linkProps = [
    'activeClassName',
    'activeStyle',
    'onClick',
    'partiallyActive',
    'replace',
    'to',
    'children',
    'target',
  ];
  const href = props.href.replace('internal:/', '');
  if ((props.href.indexOf('http') === -1 && difference(keys(props), linkProps).length) && href.indexOf('.pdf') < 0) {
    link = (
      <Link {...props} to={href}>
        {children}
      </Link>
    );
  } else {
    link = (
      <a {...props} href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return link;
};

SiteLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default SiteLink;
