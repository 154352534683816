// Leveraged from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import { useEffect, useRef } from 'react';
import { isInteger } from 'lodash';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id;

    function tick() {
      callback && savedCallback.current();
    }

    // If we have a proper delay time, begin interval. Else if falsy value (false,null,etc), ignore.
    if (isInteger(delay)) {
      id = setInterval(tick, delay);
    }

    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
