import { useMemo } from 'react';
import { has } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import { formatStoreFromNode } from '../libs/formatFromGraph';

export const useStoresStaticQuery = (id = 0, idKey = 'nid') => {
  let stores = useLoadStoreNodes();

  // Filter by ID.
  if (id) {
    // console.log('prefilter stores', id, results);
    stores = stores.find((node) => node[idKey] === parseInt(id));
    // console.log('filtered stores', id, results);
    stores = stores || {};
  }

  return stores;
};

/**
 * Load the stores from graphql for site usage.
 *
 * @returns {Array}
 */
const useLoadStoreNodes = () => {
  // For store pages on site, ensure not to include the hidden stores.
  const { allNodeStore } = useStaticQuery(graphql`
    query loadAllStoresList {
      allNodeStore(filter: {field_store_hidden_from_website: {ne: true}}) {
        edges {
          node {
            id
            drupal_internal__nid
            field_store_loft_id
            field_crm_store_id
            field_store_hidden_from_website
            title
            field_store_flyer_title
            field_store_flyer_body
            field_store_parking
            field_store_accessible_bathrooms
            field_store_accessible_entrance
            field_store_latitude
            field_store_longitude
            field_store_pharmacy_phone
            field_store_phone
            field_store_text_box
            field_store_flyer_link {
              uri
              title
            }
            flyerSubtitle: field_store_flyer_subtitle
            field_store_pharmacy_hours {
              day
              starthours
              endhours
            }
            field_store_open_hours {
              day
              starthours
              endhours
            }
            field_store_address {
              country_code
              administrative_area
              locality
              postal_code
              address_line1
            }
            pickUpDate: field_store_pick_up_date {
              value
              end_value
            }
            pickUpValid: field_store_pick_up_validation
            pickUpHours: field_store_pick_up_hours {
              endhours
              starthours
              day
            }
            path {
              langcode
              alias
            }
            relationships {
              field_holidays:field_holiday_reference {
                time:field_holiday_time {
                  from
                  to
                }
                name:field_holiday_name
                date:field_holiday_date
              }
              field_class {
                id
                drupal_internal__nid
              }
              field_store_features {
                title
                body {
                  value
                  format
                  processed
                  summary
                }
                field_sf_link {
                  uri
                  title
                }
                relationships {
                  field_sf_image {
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxHeight: 810) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              field_store_flyer_image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxHeight: 810) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              field_store_food_and_drink {
                name
              }
              field_store_services {
                name
              }
              field_store_feature_department {
                id
                title
                field_department_feature_heading {
                  value
                  format
                  processed
                }
                field_department_feature_sub {
                  value
                  format
                  processed
                }
                field_department_feature_body {
                  value
                  format
                  processed
                }
                field_department_feature_link {
                  uri
                  title
                }
                relationships {
                  field_department_image {
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxHeight: 810) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  field_department_portrait {
                    uri {
                      url
                    }
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxWidth: 200) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  // const allNodeStore = {};
  return useMemo(() => digestStoreNodes(allNodeStore), allNodeStore);
};

const digestStoreNodes = (allNodeStore) => {
  if (!has(allNodeStore, 'edges')) {
    return [];
  }
  return allNodeStore.edges.map((edge) => formatStoreFromNode(edge.node));
};
