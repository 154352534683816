const { formatClassScheduleFromNode } = require('../formatFromGraph');
const { isUndefined, compact, sortBy, values, has } = require('lodash');

const siblingType = {
  byClassNid: 'byClassNid',
  byDate: 'byDate',
  byDateTime: 'byDateTime',
};

module.exports = {
  siblingType,
  digestClassNodes,
};

const defaultClasses = () => {
  return {
    all: [],
    byClassNid: {},
    byDate: {},
    byDateTime: {},
  };
};

const defaultFilters = () => {
  return {
    locations: {},
    classTypes: {
      Adult: {},
      Kids: {},
    },
    instructors: [],
  };
};

function digestClassNodes(allNodeClassSchedule) {
  const classes = defaultClasses();
  const classFilters = defaultFilters();

  // Only process valid query data.
  if (has(allNodeClassSchedule, 'edges')) {
    allNodeClassSchedule.edges.forEach((edge) => {
      const node = formatClassScheduleFromNode(edge.node);
      classes.all.push(node);

      // Don't deal with filters missing class references., or past/expired classes.
      if (
        !(node.class && node.class.nid) ||
        !(node.store && node.store.nid) ||
        !(node.instructor && node.instructor.tid) ||
        !node.dateIsActive
      ) {
        return;
      }

      // Consolidate references
      extractRelated(classes, node);

      // Build filters
      addClassFilters(classFilters, node);
    });

    classFilters.locations = compact(sortBy(classFilters.locations, 'name'));
    classFilters.instructors = compact(sortBy(classFilters.instructors, 'name'));
    classes.byClassNid = values(classes.byClassNid);
    classes.byDate = values(classes.byDate);
    classes.byDateTime = values(classes.byDateTime);

    // if (typeof window !== 'undefined') {
    //   console.log('original classes', classes);
    // }
  }

  return [classes, classFilters];
}

/**
 * Obtain a list of filters for active classes to be leveraged as needed on site.
 *
 * @param collection
 * @param node
 */
function addClassFilters(collection, node) {
  const subCategories = node.class.subCategories;

  // Include related class types.
  if (subCategories.length) {
    const filterType = node.class.forAdults ? 'Adult' : 'Kids';
    subCategories.forEach(subCategory => collection.classTypes[filterType][subCategory.tid] = subCategory.title);
  }

  // Only include stores that are not hidden.
  if (has(node.store, 'nid') && !node.store.storeHidden) {
    collection.locations[node.store.nid] = {
      id: node.store.nid,
      name: node.store.title,
    };
  }

  // Include instructors
  if (has(node.instructor, 'tid')) {
    collection.instructors[node.instructor.tid] = {
      id: node.instructor.tid,
      name: node.instructor.title,
    };
  }
}

/**
 * Access this node to whether it's related to other class schedules, for the purpose of consolidating it into one.
 *
 * @param collection
 * @param node
 * @returns {*}
 */
function extractRelated({ byClassNid, byDate, byDateTime }, node) {
  const nodeWithRefs = getRelatedByKey(`${node.class.nid}`, siblingType.byClassNid, byClassNid, node);
  // Reference the byClass group node locations / instructors to current node.
  // This expose references on all classes nodes (as arrays are assigned by
  // reference this avoid need of extra loop).
  node.locations = nodeWithRefs.locations;
  node.instructors = nodeWithRefs.instructors;
  getRelatedByKey(`${node.class.nid}-${node.startDateRaw}`, siblingType.byDate, byDate, node);
  getRelatedByKey(`${node.class.nid}-${node.unixTimestamp}`, siblingType.byDateTime, byDateTime, node);
}

function getRelatedByKey(key, sibType, collection, node, siblingCheck) {
  // When searching for active classes only, reject those that are in the past.
  if (!node.dateIsActive) {
    return;
  }

  if (isUndefined(collection[key])) {
    // Is the first node for this key.
    collection[key] = node;
  } else {
    // A root node exits, add as sibling, allow class to know it's related
    // group key siblings.
    collection[key]._siblings[sibType][node.nid] = node;
  }
  const ref = collection[key];

  // Add this unique schedules details to the reference: store, instructor
  if (!node.store.storeHidden && ref.locations.indexOf(node.store.nid) === -1) {
    ref.locations.push(node.store.nid);
  }
  ref.locations.sort();

  if (ref.instructors.indexOf(node.instructor.tid) === -1) {
    ref.instructors.push(node.instructor.tid);
  }
  ref.instructors.sort();

  return ref;
}
