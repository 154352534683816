import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconClasses = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 24">
      <g fill="none" stroke="#404041">
        <rect width="19" height="17" x=".5" y="2.5" rx="2"/>
        <path d="M1.023 7.2h18.19M5 0v3.5M15 0v3.5"/>
      </g>
    </SvgIcon>
  );
}

export default IconClasses;
