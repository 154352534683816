import React, { useEffect, useState } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import IconRewards from '../app/icons/rewards';
import appAliases from '../../data/appAliases';
import getWindow from '../../libs/getWindow';
import IconClasses from '../app/icons/classes';
import IconAccount from '../app/icons/account';
import IconStores from '../app/icons/stores';
import styles from './AppNavigation.module.scss';
import { get, find } from 'lodash';
import IconFresh from '../app/icons/fresh';
import SelectedIconFresh from '../app/icons/freshSelected';
import { getViewingPlatform } from '../../libs/getViewingPlatform';
import { useStateValue } from '../../store/state';
import SiteLink from '../common/site-link';

export default function AppNavigation() {
  const [params, setParams] = useState(getWindow('location') || {});
  const { search = '' } = params;
  /*
  const activeKey = find(appAliases, (item) => {
    return pathname.includes(item);
  });
  console.log(":::", pathname);
  console.log("::::::", activeKey);
  */
  const [activeKey, setActiveKey] = useState('');
  const freshIcon = activeKey === appAliases.appFresh ? <SelectedIconFresh /> : <IconFresh />;
  const { iOS } = getViewingPlatform();
  const [menuSize, setMenusize] = useState(iOS ? 80 : 56);
  useEffect(() => {
    setParams(getWindow('location') || {});
    const window = getWindow();
    const document = getWindow('document');
    let webview = getWindow('ReactNativeWebView');
    if (webview) {
      webview.onMessage = (message) => {
        _onMessage(message);
      };
    }
  }, []);

  useEffect(() => {
    let { pathname } = params;
    let key = find(appAliases, (item) => {
      return item.includes(pathname);
    });
    setActiveKey(key);
  }, [params]);

  useEffect(() => {
    // console.log('::::+++', activeKey);
  }, [activeKey]);

  const [state] = useStateValue();
  const userCRMID = get(state, 'user.CRMCustomerID', '');
  if (getWindow('ReactNativeWebView')) {
    getWindow('ReactNativeWebView').postMessage(userCRMID);
  }

  const _onMessage = (data) => {
    const message =  get(data, 'message') || '';
    if (message === 'keyboardOpen') {
      setMenusize(0);
    } else if (message === 'keyboardClose') {
      setMenusize(iOS ? 80 : 56);
    }
  };
  return (
    <BottomNavigation
      className={styles.root}
      style={{ height: menuSize, overflow: 'hidden' }}
      showLabels
    >
      <div className={styles.bottomNavigation}>
        <SiteLink
          data-active={appAliases.appRewards === activeKey}
          href={appAliases.appRewards + search}
        >
          <IconRewards />
          Rewards
        </SiteLink>
        <SiteLink
          data-active={appAliases.appStores === activeKey}
          href={appAliases.appStores + search}
        >
          <IconStores />
          Stores
        </SiteLink>
        <SiteLink
          data-active={appAliases.appFresh === activeKey}
          data-fresh={true}
          href={appAliases.appFresh + search}
        >
          {freshIcon}Fresh
        </SiteLink>
        <SiteLink
          data-active={appAliases.appClasses === activeKey}
          href={appAliases.appClasses + search}
        >
          <IconClasses />
          Classes
        </SiteLink>
        <SiteLink
          data-active={appAliases.appAccountProfile === activeKey}
          href={appAliases.appAccountProfile + search}
        >
          <IconAccount />
          Account
        </SiteLink>
      </div>
    </BottomNavigation>
  );
}
