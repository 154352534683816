// Libs
import React, { useEffect } from 'react';
import { Link } from 'gatsby';
// State.
import { useStateValue } from '../../store/state';
import { completeLogout } from '../../store/user/actions';
import { LOCAL_STORAGE_KEY } from '../../store/initial-state';
// Hooks.
import useAuthenticationExpire from '../../hooks/useAuthenticationExpire';
// Assets
import icon from '../../images/icons/icon-profile.svg';
import styles from './utility-account.module.scss';
import pageAliases from '../../data/pageAliases';

export default () => {
  const [state, dispatch] = useStateValue();
  const { access_token } = state.user;
  useAuthenticationExpire(false);

  // Monitor local storage user access_token state flush.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onstorage = () => {
        try {
          const newState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
          // Access token was removed from local storage state but still present
          // in memory, another tab closed user session.
          if (newState.user.access_token === "" && access_token !== "") {
            completeLogout(dispatch);
          }
        } catch (e) {
          // TODO: we should flush all local state due parse error would data corruption.
        }
      };
    }
  }, []);

  return (
    <div className={styles.utilityMenuAccount}>
      <img className={styles.utilityMenuAccountIcon} src={icon} alt="Icon" />
      {state.user.access_token ? (
        <Link to={pageAliases.accountProfile}>My Account</Link>
      ) : (
        <Link to={pageAliases.login}>Sign In | Register</Link>
      )}
    </div>
  );
};
