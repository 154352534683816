import ActionTypes from './action-types';

// Customer loaded action creation.
export const CustomerLoaded = (customer) => ({
  type: ActionTypes.LOAD,
  payload: customer,
});

// Customer change action creation.
export const CustomerLogout = () => ({
  type: ActionTypes.LOGOUT,
});

// Customer change action creation.
export const CustomerChange = (newEmail) => ({
  type: ActionTypes.CHANGE,
  Email: newEmail,
});

// Customer create action creation.
export const CustomerCreate = (customerData) => ({
  type: ActionTypes.CREATE,
  customerData: customerData,
});

// Customer card balance loaded action creation.
export const CardBalanceLoaded = (CardBalance) => ({
  type: ActionTypes.CARD_BALANCE,
  CardBalance: CardBalance,
});

// Customer card balance loaded action creation.
export const CustomerData = (customer) => ({
  type: ActionTypes.UPDATE,
  customer: customer,
});

// Customer set default store.
export const CustomerSetMyStore = (storeId) => ({
  type: ActionTypes.SET_STORE,
  payload: {
    PreferredStoreNumber: storeId,
  },
});

// TYR card loaded.
export const CardLoaded = (cardAndPoints) => ({
  type: ActionTypes.CARD_LOADED,
  payload: cardAndPoints,
});

// Customer cards list.
export const CardsListLoaded = (CardsList) => ({
  type: ActionTypes.CARDS_LIST,
  CardsList: CardsList,
});

// Remove customer card from list.
export const CardRemove = (Card) => ({
  type: ActionTypes.CARD_REMOVE,
  Card: Card,
});

// Customer save new default card.
export const SaveNewCard = (Card) => ({
  type: ActionTypes.CARD_UPDATE,
  Card: Card,
});

// Customer save new default card.
export const ValidateNewCard = (Card) => ({
  type: ActionTypes.CARD_VALIDATE,
  Card: Card,
});

// Add new card to Customer.
export const AddNewCard = (CardNumber) => ({
  type: ActionTypes.CARD_ADD,
  CardNumber,
});
// Replace Customer Card.
export const ReplaceCard = (CardNumber) => ({
  type: ActionTypes.CARD_REPLACE,
  CardNumber,
});
