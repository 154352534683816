import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

export const useImageStaticQuery = (path = '', cmsImage = null) => {
  const { allFile } = useStaticQuery(graphql`
      query loadAllImagesList {
          allFile {
              edges {
                  node {
                      id
                      relativePath
                      publicURL
                      childImageSharp {
                          fluid(quality: 100) {
                              ...GatsbyImageSharpFluid
                              presentationWidth
                          }
                      }
                      lg: childImageSharp {
                          fluid(quality: 100, maxWidth: 1170) {
                              ...GatsbyImageSharpFluid
                              presentationWidth
                          }
                      }
                  }
              }
          }
      }
  `);

  return useMemo(() => {
      const node = allFile.edges.find((file) => {
        return file.node.relativePath === path;
      }) || { node: null };
      // Attempt to pull the node for this static file, either the sharp image, or publicURL.
      const resultingFile = get(node || {}, 'node');
      // Now return either the cms image, or the static image for fallback.
      return cmsImage || resultingFile || {};
    },
    [path]);
};

useImageStaticQuery.defaultProps = {
  path: '',
};

useImageStaticQuery.propTypes = {
  path: PropTypes.string.isRequired,
};
