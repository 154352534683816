/**
 * Returns number before and after decimals for ease.
 * @param x
 * @param y
 * @returns {number[]}
 */
export function divmod(x, y) {
  let div = 0;
  let rem = 0;
  try {
    div = Math.trunc(x / y);
    rem = x % y;
  } catch (e) {
  }
  return [div, rem];
}
