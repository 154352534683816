import ModalActionTypes from './action-types';

// Add cart line item.
export const OpenModal = (payload) => ({
  type: ModalActionTypes.OPEN,
  payload,
});

// Remove cart line item.
export const CloseModal = () => ({
  type: ModalActionTypes.CLOSE,
});
