import React from 'react';
import SiteLink from '../common/site-link';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';

import { footer } from '../../locale/locale.json';

const FooterAccessibilityIcon = () => {
  const accessibilityLogo = useImageStaticQuery('Accessibility.svg');
  return (
    <SiteLink
      alt={footer.accessibility_statement}
      target="_blank"
      rel="noreferrer"
      href="https://www.essentialaccessibility.com/longos/?utm_source=longoshomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=longos"
    >
      <img src={accessibilityLogo.publicURL} alt="logo"/>
    </SiteLink>
  );
};
export default FooterAccessibilityIcon;
