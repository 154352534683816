// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
// Assets.
import styles from './success-messages.module.scss';

const SuccessMessages = ({ messages }) => {
  return (
    <ul className={styles.successMessages}>
      {messages.map((message, idx) => (
        <li key={idx}>{message}</li>
      ))}
    </ul>
  );
};

SuccessMessages.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default SuccessMessages;
