import { storeCrmId, storeDrupalId } from '../libs/store/store-utils';
import { useStoresStaticQuery } from '../hooks/useStoresStaticQuery';

/**
 * Index stores collection by multiples ID fields for fast lookup.
 *
 * @class storesIndexService
 */
class storesIndexService {
  stores = [];
  storesByCrmId = [];
  storesByDrupalId = [];
  static instance;

  /**
   * Creates an instance of storesIndexService.
   *
   * @param {Array} stores - All stores collection.
   * @memberof storesIndexService
   */
  constructor(stores) {
    if (storesIndexService.instance) {
      return storesIndexService.instance;
    }

    this.stores = stores;
    this.indexByCrmId();
    this.indexByDrupalId();
    storesIndexService.instance = this;
  }

  /**
   * Create stores positions index by given property ID getter callback.
   *
   * @param {function} indexPropGet - The ID property getter.
   * @returns {Object} - Stores position index by specified property ID.
   * @memberof storesIndexService
   */
  indexByPropId(indexPropGet) {
    let index = {};
    for (const [position, store] of this.stores.entries()) {
      const indexId = indexPropGet(store);
      if (indexId) {
        index[indexId] = position;
      }
    }

    return index;
  }

  /**
   * Index stores by CRM ID.
   *
   * @memberof storesIndexService
   */
  indexByCrmId() {
    this.storesByCrmId = this.indexByPropId(storeCrmId);
  }

  /**
   * Index stores by Drupal ID.
   *
   * @memberof storesIndexService
   */
  indexByDrupalId() {
    this.storesByDrupalId = this.indexByPropId(storeDrupalId);
  }

  /**
   * Get store by CRM ID.
   *
   * @param {Number} id - Store CRM ID.
   * @returns {Object} - Store object if index found, empty object otherwise.
   * @memberof storesIndexService
   */
  getByCrmId(id) {
    const position = this.storesByCrmId[id];
    return this.stores[position] || {};
  }

  /**
   * Get store by Drupal ID.
   *
   * @param {Number} id - Store Drupal ID.
   * @returns {Object} - Store object if index found, empty object otherwise.
   * @memberof storesIndexService
   */
  getByDrupalId(id) {
    const position = this.storesByDrupalId[id];
    return this.stores[position] || {};
  }

  /**
   * Get all the stores.
   *
   * @returns - All stores collection.
   * @memberof storesIndexService
   */
  getAll() {
    return this.stores;
  }
}

// Initialize singleton instance.
export function useStores() {
  const stores = useStoresStaticQuery();
  const singleton = new storesIndexService(stores);
  Object.freeze(singleton);

  return singleton;
}
